import React, { useEffect } from "react";

import A from "./A";

const Modal = ({ closeModal, children }) => {
	const escHandler = e => {
		const target = e.target;
		if (e.keyCode === 27 && !target.id.startsWith("react-select-")) {
			e.stopPropagation();
			closeModal();
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", escHandler, false);
		return () => {
			document.removeEventListener("keydown", escHandler, false);
		};
	}, []);

	return (
		<div className="modal__holder popup-login modal-holder open" id="popup-reg">
			<div className="modal-inner">
				<div className="holder-popup">
					<div className="bg js-close-modal close-modal">&nbsp;</div>
					<div className="tabset">
						<div className="tab-body">
							<A className="modal__close-ico js-close-modal close-modal" onClick={closeModal}>
								<img src="/images/close.svg" />
							</A>
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;
