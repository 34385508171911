import React, { useEffect, useRef, useState } from "react";
import ImageCrop from "./ImageCrop";

export const PhotoSelector = ({ initialPhoto, setPhotoData, typeFilter }) => {
	const [photo, setPhoto] = useState();
	const [crop, setCrop] = useState();
	const [openCrop, setOpenCrop] = useState(false);
	const previewImg = useRef(null);

	const onSelectPhoto = e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => {
				setCrop(null);
				setPhoto(reader.result);
				setOpenCrop(true);
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const closeCrop = () => {
		setOpenCrop(false);
	};

	const canvasSizes = { width: 164, height: 164 };
	const pixelRatio = 4;

	useEffect(() => {
		if (photo && crop && previewImg.current) {
			const canvas = previewImg.current;
			const ctx = canvas.getContext("2d");

			canvas.width = canvasSizes.width * pixelRatio;
			canvas.height = canvasSizes.height * pixelRatio;

			ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
			ctx.imageSmoothingEnabled = false;

			const image = new Image();
			image.onload = () => {
				const imageSize = { width: image.width, height: image.height };

				if (typeof setPhotoData === "function") {
					setPhotoData({ crop, photo, imageSize });
				}

				ctx.drawImage(
					image,
					(crop.x * image.width) / 100,
					(crop.y * image.height) / 100,
					(crop.size * image.width) / 100,
					(crop.size * image.width) / 100,
					0,
					0,
					canvasSizes.width,
					canvasSizes.height
				);
			};
			image.src = photo;
		}
	}, [crop]);

	return (
		<>
			<div className="upload-photos">
				{crop && photo ? (
					<canvas className="preview-canvas" ref={previewImg} />
				) : (
					<>
						{initialPhoto ? (
							<>
								<img src={initialPhoto} />
							</>
						) : (
							<label>
								<span>Please add a logo (jpeg, up to 5 mb)</span>
							</label>
						)}
					</>
				)}
				<input type="file" className="js-file-input" accept={typeFilter} onChange={onSelectPhoto} />
			</div>
			{openCrop && <ImageCrop closeModal={closeCrop} photo={photo} setPhotoCrop={setCrop} />}
		</>
	);
};
