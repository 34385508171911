import React from "react";
import { useSelector } from "react-redux";

import { getSession } from "../../core/store/appSlice";
import { getUsers } from "../../core/store/usersSlice";
import { getMemberName } from "../../core/services/utils.service";

import Breadcrumbs from "./Breadcrumbs";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children, breadcrumbs, title }) => {
	const userSession = useSelector(state => getSession(state));
	const users = useSelector(state => getUsers(state));
	const user = userSession ? users[userSession.user_id] : null;

	return (
		<>
			<Header userName={getMemberName(user)} />
			<main id="main">
				<div className="main-icon-graph"></div>
				<div className="center">
					<div id="content">
						<Breadcrumbs breadcrumbs={breadcrumbs} />
						{title && <h1>{title}</h1>}
						{children}
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
};

export default Layout;
