import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { formatAge, getMemberName } from "../../../core/services/utils.service";
import { getSession } from "../../../core/store/appSlice";
import { PRIVILEGES_LEVELS } from "../../../core/store/usersSlice";
import { getInstructorsLevelsForCountry, getUmpiresLevelsForCountry } from "../../../core/store/dictionariesSlice";

import A from "../../A";
import AddMemberForm from "./AddMemberForm";
import Club from "../Club/Club";
import Dan from "../Dan";
import Certificates from "./Certificates";

const Member = ({ user, club, clubs }) => {
	const userSession = useSelector(state => getSession(state));
	const countries = useSelector(state => state.pages.dictionaries.countries);
	const instructorsLevelsByCountries = useSelector(state => getInstructorsLevelsForCountry(state));
	const umpiresLevelsByCountries = useSelector(state => getUmpiresLevelsForCountry(state));

	const [showEditForm, setShowEditForm] = useState(false);
	const openAddFrom = () => {
		setShowEditForm(true);
	};

	const closeAddFrom = () => {
		setShowEditForm(false);
	};

	if (!user) {
		return <></>;
	}

	let allowEdit =
		(userSession && (user.id === userSession.user_id || user.coach === userSession.user_id)) ||
		(club && club.myPrivileges && club.myPrivileges.indexOf(PRIVILEGES_LEVELS.PersonalEdit) > -1);

	const phones = [user.phone1, user.phone2, user.phone3].filter(item => item);

	return (
		<>
			<header className="content-header">
				<ul className="content-nav">
					<li className="active">
						<A>Info</A>
					</li>
				</ul>
				<span className="content-header-info">
					<span>ITF ID for Athletes (IDA).</span>
					<i className="icon-check">
						<img src="/images/check-main.svg" />
					</i>
					<div className="dropdown">
						<span>The IDA is for any athlete/practitioner who registers for the Database.</span>
					</div>
				</span>
			</header>
			<div className="info-wrapper-blocks">
				<div className="two-columns">
					<div className="col">
						<div className="info-block">
							<header className="participant-head">
								<span className="name">
									{getMemberName(user)}{" "}
									{allowEdit && (
										<div className="members-action-block">
											<A className="edit-button" title="Edit member" onClick={openAddFrom}>
												<img src="/images/edit.svg" />
											</A>
										</div>
									)}
								</span>
								<span className="country">
									<i className="ico-flag">
										<img src={`/images/flags/${user.country.toLowerCase()}.svg`} width="23" />
									</i>
									{countries[user.country]}
								</span>

								<div className="rang-info">
									<Dan degree={user.degree} withText />
								</div>
							</header>
							<div className="participant-block">
								<div className="participant-ava">
									<img
										className="ava-image"
										src={`https://data.taekwondo-itf.com/user_${user.id}_100.jpg?t=${user.photo_force}`}
										width="100"
									/>
								</div>
								<div className="participant-info">
									<h3>Participant information</h3>
									<ul className="info-block-list">
										<li>
											<span className="title">First name</span>
											<span className="info">{user.given_name}</span>
										</li>
										<li>
											<span className="title">Last name</span>
											<span className="info">{user.family_name}</span>
										</li>
										<li>
											<span className="title">Patronymic</span>
											<span className="info">{user.fathers_name}</span>
										</li>
										<li>
											<span className="title">First name (national)</span>
											<span className="info">{user.n_given_name}</span>
										</li>
										<li>
											<span className="title">Last name (national)</span>
											<span className="info">{user.n_family_name}</span>
										</li>
										<li>
											<span className="title">Patronymic (national)</span>
											<span className="info">{user.n_fathers_name}</span>
										</li>
										<li>
											<span className="title">Birthday</span>
											<span className="info">{user.birth}</span>
										</li>
										<li>
											<span className="title">Age</span>
											<span className="info">{formatAge(user.age)}</span>
										</li>
										<li>
											<span className="title">Sex</span>
											<span className="info">{user.sex === "M" ? "Male" : "Female"}</span>
										</li>
										<li>
											<span className="title">Email</span>
											<span className="info">{user.email}</span>
										</li>
										<li>
											<span className="title">Mobile phone</span>
											<span className="info info-list">
												{phones.map((item, i) => (
													<div key={i}>{item}</div>
												))}
											</span>
										</li>
										{user.coach && (
											<li>
												<span className="title">Coach</span>
												<span className="info">
													<Link to={`/member/${user.coach}`}>{user.coach_fullname}</Link>
												</span>
											</li>
										)}
										{user.firstcoach && (
											<li>
												<span className="title">First coach</span>
												<span className="info">
													<Link to={`/member/${user.firstcoach}`}>{user.firstcoach_fullname}</Link>
												</span>
											</li>
										)}
										{typeof user.instructor_level === "number" && (
											<li>
												<span className="title">Instructor level</span>
												<span className="info">{instructorsLevelsByCountries(user.country)[user.instructor_level]}</span>
											</li>
										)}
										{typeof user.umpire_level === "number" && (
											<li>
												<span className="title">Umpire level</span>
												<span className="info">{umpiresLevelsByCountries(user.country)[user.umpire_level]}</span>
											</li>
										)}
									</ul>
									<h3>Supplementary information</h3>
									<ul className="info-block-list">
										<li>
											<span className="title">User id</span>
											<span className="info">{user.member_id}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
						{club && <Club club={club} />}
						{clubs && clubs.map(item => <Club key={item.id} club={item} />)}
					</div>
					<div className="col">
						<Certificates user={user.id} allowEdit={allowEdit} country={user.country} />
					</div>
				</div>
			</div>
			{showEditForm && <AddMemberForm closeModal={closeAddFrom} editedUser={user} />}
		</>
	);
};

export default Member;
