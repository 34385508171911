import { createSelector, createSlice } from "@reduxjs/toolkit";
import ApiService from "../services/api.service";
import LocalStorageService from "../services/localStorage.service";
import { push } from "connected-react-router";
import { loadMainFederations } from "./federationsSlice";
import { loadDictionaries } from "./dictionariesSlice";
import { loadUser } from "./usersSlice";

export const APP_REDUCER_KEY = "app";
export const APP_USER_SESSION_LOCALSTORAGE_KEY = process.env.REACT_APP_USER_SESSION_LOCALSTORAGE_KEY;

const appSlice = createSlice({
	name: APP_REDUCER_KEY,
	initialState: {
		session: null
	},
	reducers: {
		setSession: (state, { payload }) => {
			state.session = payload;
			LocalStorageService.setItem(APP_USER_SESSION_LOCALSTORAGE_KEY, payload);
		},
		setPrivileges: (state, { payload }) => {
			state.session.privileges = payload;
		}
	}
});

// export const { initApp } = appSlice.actions;

// selectors
const getAppState = state => state[APP_REDUCER_KEY];
export const getSession = state => getAppState(state).session;
// export const getUser = createSelector([getSession], session => {
// 	if (session) {
// 		return session.user;
// 	} else {
// 		return {};
// 	}
// });

// export const getUserClubs = createSelector([getSession], session => {
// 	if (session) {
// 		return session.clubs;
// 	} else {
// 		return [];
// 	}
// });

// actions
export const initApp = () => async dispatch => {
	const storedSession = LocalStorageService.getItem(APP_USER_SESSION_LOCALSTORAGE_KEY);
	if (storedSession) {
		dispatch(appSlice.actions.setSession(storedSession));
		dispatch(loadUser(storedSession.user_id));
		dispatch(loadData());
	} else {
		if (window.location.pathname !== "/") {
			window.location.href = "/";
		}
	}
};

const loadData = () => dispatch => {
	// dispatch(getPrivileges());
	dispatch(loadMainFederations(true));
	dispatch(loadDictionaries());
};

export const login = (login, password) => async dispatch => {
	const loginResult = await ApiService.login(login, password);
	if (loginResult.error) {
		return loginResult;
	} else {
		const session = {
			openKey: loginResult.openKey,
			privateKey: loginResult.privateKey,
			user_id: loginResult.userId ?? loginResult.user.id
		};
		dispatch(appSlice.actions.setSession(session));
		dispatch(loadUser(session.user_id));
		dispatch(loadData());
		dispatch(push("/home"));
	}
};

export const getPrivileges = () => async (dispatch, getState) => {
	const userSession = getSession(getState());

	if (!userSession.user_id) {
		return;
	}

	let { privileges } = await ApiService.getUserPrivileges(userSession.user_id);

	if (Array.isArray(privileges)) {
		privileges = privileges.map(item => ({
			club_id: item.club_id,
			privilege: item.privilege
		}));
		dispatch(appSlice.actions.setPrivileges(privileges));
	}
};

export const logout = () => async dispatch => {
	dispatch(appSlice.actions.setSession(null));
	dispatch(push("/"));
};

export default appSlice.reducer;
