import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";

import { employeePositions } from "../../../core/store/dictionariesSlice";
import { getFullpositions, loadFullClubPositions } from "../../../core/store/federationsSlice";
import { loadUsersOptions } from "../../../core/store/usersSlice";

import Select from "../../Select";
import A from "../../A";
import { getMemberName } from "../../../core/services/utils.service";

const FederationFormPositions = ({ clubId, updateHandler, setPreviousPositions }) => {
	const dispatch = useDispatch();

	const employeePositionsForShow = useSelector(state => employeePositions(state));
	const fullPositions = useSelector(state => getFullpositions(state));

	const [positions, setPositions] = useState({});

	const fullClubPositions = fullPositions[clubId];

	useEffect(() => {
		if (clubId) {
			dispatch(loadFullClubPositions(clubId));
		}
	}, [clubId]);

	useEffect(() => {
		if (typeof fullClubPositions === "object") {
			let positionsData = {};
			for (let position_id in fullClubPositions) {
				positionsData[position_id] = fullClubPositions[position_id].map(item => ({ label: getMemberName(item), value: item.user_id }));
			}

			employeePositionsForShow.forEach(item => {
				if (item.max_count === 1) {
					if (positionsData[item.id]) {
						positionsData[item.id] = positionsData[item.id][0];
					} else {
						positionsData[item.id] = null;
					}
				} else {
					if (!positionsData[item.id]) {
						positionsData[item.id] = [];
					}
				}
			});

			setPositions(positionsData);
			setPreviousPositions(JSON.parse(JSON.stringify(positionsData)));
		}
	}, [fullClubPositions]);

	const loadDebouncedOptions = useCallback(
		debounce((inputValue, callback) => {
			loadUsersOptions(inputValue).then(options => callback(options));
		}, 300),
		[]
	);

	const singleChanged = (value, position_id) => {
		setPositions(positions => {
			const newPositions = {
				...positions,
				[position_id]: value
			};
			updateHandler(newPositions);
			return newPositions;
		});
	};

	const multiChanged = (value, position_id) => {
		const exists = positions[position_id].find(item => item.value === value.value);

		if (!exists) {
			setPositions(positions => {
				positions[position_id].push(value);
				updateHandler({ ...positions });
				return { ...positions };
			});
		}
	};

	const removeMultiItem = (position_id, index) => {
		setPositions(positions => {
			positions[position_id].splice(index, 1);
			updateHandler({ ...positions });
			return { ...positions };
		});
	};

	if (Object.keys(positions).length !== employeePositionsForShow.length) {
		return <></>;
	}

	return (
		<>
			{/* <div className="hr" /> */}
			{employeePositionsForShow.map(item => {
				if (item.max_count === 1) {
					return (
						<div className="form-row holder-error" key={item.id}>
							<label>
								<span className="label">{item.name}</span>
								<Select
									placeholder={""}
									async
									loadOptions={loadDebouncedOptions}
									isClearable={true}
									onChange={value => singleChanged(value, item.id)}
									value={positions[item.id]}
								></Select>
							</label>
						</div>
					);
				} else {
					return (
						<div className="form-row holder-error" key={item.id}>
							<label>
								<span className="label">{item.name}</span>
								{positions[item.id].map((pos, i) => (
									<div className="positions-item" key={i}>
										{pos.label}{" "}
										<A onClick={() => removeMultiItem(item.id, i)}>
											<img src="/images/close.svg" width="10" height="10" />
										</A>
									</div>
								))}
								<Select
									placeholder={""}
									async
									loadOptions={loadDebouncedOptions}
									onChange={value => multiChanged(value, item.id)}
									value={null}
								></Select>
							</label>
						</div>
					);
				}
			})}
		</>
	);
};

export default FederationFormPositions;
