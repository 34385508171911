import axios from "axios";
import sha1 from "js-sha1";
import LocalStorageService from "./localStorage.service";
import { getInstance as getErrorService } from "./errors.service";

const apiURL = process.env.REACT_APP_API_URL;
const APP_USER_SESSION_LOCALSTORAGE_KEY = process.env.REACT_APP_USER_SESSION_LOCALSTORAGE_KEY;
const USERS_PAGE_SIZE = process.env.REACT_APP_USERS_PAGE_SIZE;

class ApiService {
	static async sendRequest(action, data, imgData) {
		let queryString = `action=${action}`;
		const params = new URLSearchParams();

		if (imgData) {
			params.append("imgData", imgData);
			data.imgHash = sha1(imgData);
		}

		const jsonData = JSON.stringify(data);
		params.append("jsonData", jsonData);

		if (action !== "Login") {
			const session = LocalStorageService.getItem(APP_USER_SESSION_LOCALSTORAGE_KEY);

			if (!session) {
				getErrorService().logout();
				return;
			}

			queryString += `&openKey=${session.openKey}`;
			const signature = sha1(`${queryString}${session.privateKey}${jsonData}`);
			params.append("signature", signature);
		}

		const url = `${apiURL}?${queryString}`;

		let result = {};

		try {
			const response = await axios({
				url,
				method: "post",
				data: params,
				headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
			});

			if (response.data.status && response.data.status === "error") {
				result.error = getErrorService().processError(response.data.message);
			} else {
				result = response.data;
			}
		} catch (err) {
			console.log("api request error", err);
			result.error = err;
		}

		return result;
	}

	static async sendRequestMultiple(data) {
		let queryString = `action=Multiple`;
		const params = new URLSearchParams();

		const jsonData = JSON.stringify(data);
		params.append("jsonData", jsonData);

		const session = LocalStorageService.getItem(APP_USER_SESSION_LOCALSTORAGE_KEY);

		if (!session) {
			getErrorService().logout();
			return;
		}

		queryString += `&openKey=${session.openKey}`;
		const signature = sha1(`${queryString}${session.privateKey}${jsonData}`);
		params.append("signature", signature);

		const url = `${apiURL}?${queryString}`;

		let result = {};

		try {
			const response = await axios({
				url,
				method: "post",
				data: params,
				headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
			});

			if (response.data.status && response.data.status === "error") {
				result.error = getErrorService().processError(response.data.message);
			} else {
				result = response.data;
			}
		} catch (err) {
			console.log("api request error", err);
			result.error = err;
		}

		return result;
	}

	static login(login, password) {
		const data = { emailLogin: { email: login, password } };
		return ApiService.sendRequest("Login", data);
	}

	static helloWorld() {
		return ApiService.sendRequest("HelloWorld", {});
	}

	static getClubs(clubId, levels) {
		return ApiService.sendRequest("GetClubs", { startFrom: clubId, levels, includeKeyPositions: true, includeMyPrivileges: true });
	}

	static getParentsClubs(clubId, levels) {
		return ApiService.sendRequest("GetClubs", { startFrom: clubId, levels, parents: true, includeKeyPositions: true, includeMyPrivileges: true });
	}

	static getUsers(clubId, clubStrict = false, page = 1) {
		page--;
		const offset = USERS_PAGE_SIZE * page;
		const count = USERS_PAGE_SIZE;

		const params = {
			clubId,
			offset,
			count,
			order: "degree desc, given_name, family_name, id",
			fields: ["id", "default_image", "given_name", "family_name", "n_given_name", "n_family_name", "sex", "age", "degree"]
		};

		if (clubStrict) {
			params.where = "club_id = :c";
			params.params = { c: clubId };
		}

		return ApiService.sendRequest("GetUsers", params);
	}

	static async getUser(id) {
		const params = { offset: 0, count: 1, where: `id = :id`, params: { id } };
		const { users } = await ApiService.sendRequest("GetUsers", params);
		if (Array.isArray(users) && users.length) {
			return users[0];
		} else {
			return null;
		}
	}

	static getUserPrivileges(userId) {
		return ApiService.sendRequest("GetUsersClubsPrivileges", { userId });
	}

	static getUserClubs(userId) {
		const fields = [
			"id",
			"city",
			"is_member",
			"club_level",
			"country",
			"name",
			"official_name",
			"social_media",
			"children_count",
			"positions",
			"myPrivileges"
		];
		return ApiService.sendRequest("GetUserClubs", { userId, includeKeyPositions: true, includeMyPrivileges: true });
	}

	static getCountriesList(userId) {
		return ApiService.sendRequest("GetCountries", {});
	}

	static addClub(fields) {
		return ApiService.sendRequest("AddClub", { fields });
	}

	static updateClub(fields) {
		return ApiService.sendRequest("UpdateClub", { fields });
	}

	// static getUsersClubsPrivileges(userId, clubId) {
	// 	const params = { effective: true };
	// 	if (userId) {
	// 		params.userId = userId;
	// 	}
	// 	if (clubId) {
	// 		params.clubId = clubId;
	// 	}
	// 	return ApiService.sendRequest("GetUsersClubsPrivileges", params);
	// }

	static GetUsersClubsPositionsForClub(clubId) {
		return ApiService.sendRequest("GetUsersClubsPositions", {
			clubId,
			getUsers: ["id", "given_name", "family_name", "n_given_name", "n_family_name", "phone1", "phone2", "phone3"]
		});
	}

	static GetClubsPriveleges(clubId) {
		return ApiService.sendRequest("GetUsersClubsPrivileges", { clubId, getUsers: ["id", "given_name", "family_name", "n_given_name", "n_family_name"] });
	}

	static GetEmployeePositions() {
		return ApiService.sendRequest("GetEmployeePositions", {});
	}

	static GetDegrees() {
		return ApiService.sendRequest("GetDegrees", {});
	}

	static GetPrivileges() {
		return ApiService.sendRequest("GetPrivileges", {});
	}

	static GetInstructorsLevels() {
		return ApiService.sendRequest("GetInstructorsLevels", {});
	}

	static GetUmpiresLevels() {
		return ApiService.sendRequest("GetUmpiresLevels", {});
	}

	static GetSocialMediaTypes() {
		return ApiService.sendRequest("GetSocialMediaTypes", {});
	}

	static GetCertificatesTypes() {
		return ApiService.sendRequest("GetCertificatesTypes", {});
	}

	// static GetUsersCertificates(userId) {
	// 	const fields = ["user_certificate_type_id", "rank", "date_issued", "certificate_number", "date_created"];
	// 	return ApiService.sendRequest("GetUsersCertificates", { offset: 0, count: 100, user_id: userId });
	// }

	static GetUserCertificates(user_id) {
		const fields = [
			"id",
			"user_certificate_type_id",
			"rank",
			"date_issued",
			"certificate_number",
			"date_created",
			"instructor_degree",
			"instructor_id",
			"instructor_fullname"
		];
		return ApiService.sendRequest("GetUsersCertificates", { offset: 0, count: 100, where: `user_id = :user_id`, params: { user_id }, fields });
	}

	static AddUsersCertificate(fields) {
		return ApiService.sendRequest("AddUsersCertificate", { fields });
	}

	static DeleteUsersCertificate(id) {
		return ApiService.sendRequest("DeleteUsersCertificate", { id });
	}

	static addUser(fields) {
		return ApiService.sendRequest("AddUser", { fields });
	}

	static updateUser(fields) {
		return ApiService.sendRequest("UpdateUser", { fields });
	}

	static uploadPicture(imgData, scope, id, x, y, size) {
		return ApiService.sendRequest("UploadUserPicture", { scope, id, x, y, size }, imgData);
	}

	static suggestUser(input, onlyInstructors) {
		let where = "(lower(given_name) like :s or lower(n_given_name) like :s or lower(family_name) like :s or lower(n_family_name) like :s)";

		if (onlyInstructors) {
			where = `(instructor_level > 0 and ${where})`;
		}

		return ApiService.sendRequest("GetUsers", {
			where,
			params: { s: `%${input.toLowerCase().replace(/\s/g, "%")}%` },
			count: 10,
			offset: 0,
			fields: ["id", "given_name", "family_name", "n_given_name", "n_family_name"],
			order: "degree desc"
		});
	}

	static grantPosition(userId, clubId, position) {
		return ApiService.sendRequest("Grant", { userId, clubId, positions: [position] });
	}
	static revokePsition(userId, clubId, position) {
		return ApiService.sendRequest("Revoke", { userId, clubId, positions: [position] });
	}

	static grantPrivileges(userId, clubId, privileges) {
		return ApiService.sendRequest("Grant", { userId, clubId, privileges });
	}
	static revokePrivileges(userId, clubId, privileges) {
		return ApiService.sendRequest("Revoke", { userId, clubId, privileges });
	}

	static GetDictionaries() {
		const actions = [
			"GetCountries",
			"GetEmployeePositions",
			"GetDegrees",
			"GetPrivileges",
			"GetInstructorsLevelsFull",
			"GetUmpiresLevelsFull",
			"GetSocialMediaTypes"
		];
		return ApiService.sendRequestMultiple(actions.map(action => ({ action, params: {} })));
	}

	static DeleteUser(id) {
		return ApiService.sendRequest("DeleteUser", { id });
	}

	static DeleteClub(id) {
		return ApiService.sendRequest("DeleteClub", { id });
	}

	static GetGyms(clubId) {
		return ApiService.sendRequest("GetGyms", { offset: 0, count: 100, clubId, order: "name asc", schedule: 1 });
	}

	static AddGym(fields) {
		return ApiService.sendRequest("AddGym", { fields });
	}

	static UpdateGym(fields) {
		return ApiService.sendRequest("UpdateGym", { fields });
	}

	static DeleteGym(id) {
		return ApiService.sendRequest("DeleteGym", { id });
	}

	static AddSchedule(fields) {
		return ApiService.sendRequest("AddSchedule", { fields });
	}

	static DeleteSchedule(id) {
		return ApiService.sendRequest("DeleteSchedule", { id });
	}

	static UpdateSchedule(fields) {
		return ApiService.sendRequest("UpdateSchedule", { fields });
	}

	static AddScheduleUsers(fields) {
		return ApiService.sendRequest("AddScheduleUsers", { fields });
	}
}

export default ApiService;
