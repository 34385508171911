import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { deleteGymSchedule } from "../../../../core/store/federationsSlice";

import A from "../../../A";
import AddScheduleForm from "./AddScheduleForm";
import ScheduleMembers from "./ScheduleMembers";

const Schedules = ({ schedules, club, gymId, gymName, allowEdit }) => {
	const dispatch = useDispatch();
	const [showAddForm, setShowAddForm] = useState(false);
	const [editScheduleId, setEditScheduleId] = useState(null);
	const [showScheduleMembers, setShowScheduleMembers] = useState(null);

	const toggleForm = useCallback(() => {
		setShowAddForm(val => !val);
	}, [setShowAddForm]);

	const closeForm = useCallback(() => {
		setShowAddForm(false);
	}, [setShowAddForm]);

	const deleteSchedule = useCallback(e => {
		const id = +e.currentTarget.dataset["scheduleId"];
		if (window.confirm("Are you sure to delete this?")) {
			dispatch(deleteGymSchedule(id, club));
		}
	}, []);

	const editSchedule = useCallback(e => {
		const id = +e.currentTarget.dataset["scheduleId"];
		setEditScheduleId(id);
	}, []);

	const cancelEdit = useCallback(() => {
		setEditScheduleId(null);
	}, []);

	const showMembers = useCallback(e => {
		const id = +e.currentTarget.dataset["scheduleId"];
		const schedule = schedules.find(item => item.id === id);
		setShowScheduleMembers(schedule);
	}, []);

	const hideMembers = useCallback(() => {
		setShowScheduleMembers(null);
	}, []);

	return (
		<>
			<ul className="address-list schedules">
				{schedules.map(item => (
					<li key={item.id} className="schedules-row">
						{item.id === editScheduleId ? (
							<AddScheduleForm gymId={gymId} club={club} editSchedule={item} closeForm={cancelEdit} />
						) : (
							<>
								<address>
									<span>
										<Link to={`/member/${item.coach}`}>{item.coach_name}</Link>
									</span>
									{item.schedule}
								</address>

								<div className="buttons-block">
									{allowEdit && (
										<button className="edit-button" title="Edit schedule" data-schedule-id={item.id} onClick={editSchedule}>
											<img src="/images/edit.svg" />
										</button>
									)}
									<button className="edit-button" title="Show members" data-schedule-id={item.id} onClick={showMembers}>
										<img src="/images/users.svg" width="18" height="18" />
									</button>
									{allowEdit && (
										<button className="edit-button" title="Remove schedule" data-schedule-id={item.id} onClick={deleteSchedule}>
											<img src="/images/remove.svg" width="18" height="18" />
										</button>
									)}
								</div>
							</>
						)}
					</li>
				))}
			</ul>
			<A className="small-action-link" onClick={toggleForm}>
				<img src="/images/add.svg" width="18" height="18" />
				Add schedule
			</A>
			{showAddForm && <AddScheduleForm gymId={gymId} club={club} closeForm={closeForm} />}
			{showScheduleMembers && <ScheduleMembers schedule={showScheduleMembers} gymName={gymName} closeModal={hideMembers} allowEdit={allowEdit} />}
		</>
	);
};

export default Schedules;
