import React, { useCallback } from "react";

const A = ({ children, onClick, ...props }) => {
	const handleClick = useCallback(
		e => {
			e.preventDefault();
			if (typeof onClick === "function") {
				return onClick(e);
			}
		},
		[onClick]
	);
	return (
		<a href="#!" {...props} onClick={handleClick}>
			{children}
		</a>
	);
};

export default A;
