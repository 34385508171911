import React, { useCallback, useEffect, useRef, useState } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

import { isUoN } from "../core/services/utils.service";

const initialCoords = { lat: 48.28613, lng: 16.43856 };

const GMapInner = withScriptjs(
	withGoogleMap(({ positionSelectorHandler, lat, lng, zoom }) => {
		if (!zoom) {
			zoom = 10;
		}

		const mapRef = useRef(null);
		const [markerCoords, setMarkerCoords] = useState(null);

		useEffect(() => {
			if (!isUoN(lat) && !isUoN(lng)) {
				setMarkerCoords({ lat, lng });
				mapRef.current.panTo(new window.google.maps.LatLng(lat, lng));
			}
		}, [lat, lng]);

		const mapClicked = useCallback(e => {
			if (typeof positionSelectorHandler === "function") {
				const lat = e.latLng.lat();
				const lng = e.latLng.lng();
				setMarkerCoords({ lat, lng });
				positionSelectorHandler({ lat, lng });
			}
		}, []);

		return (
			<GoogleMap ref={mapRef} defaultZoom={zoom} defaultCenter={initialCoords} onClick={mapClicked}>
				{markerCoords && <Marker position={markerCoords} />}
			</GoogleMap>
		);
	})
);

const GMap = props => {
	const { height, ...restProps } = props;
	return (
		<GMapInner
			googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.REACT_APP_GMAPS_API_KEY}`}
			loadingElement={<div style={{ height: `100%` }} />}
			containerElement={<div style={{ height: height ?? 400, marginBottom: 28 }} />}
			mapElement={<div style={{ height: `100%` }} />}
			{...restProps}
		/>
	);
};

export default GMap;

// GMap.whyDidYouRender = true;
