import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import Modal from "../Modal";

const ImageCrop = ({ closeModal, photo, setPhotoCrop }) => {
	const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 1 / 1 });
	const [pixelCrop, setPixelCrop] = useState();

	const submit = () => {
		if (typeof setPhotoCrop === "function") {
			setPhotoCrop({ x: pixelCrop.x, y: pixelCrop.y, size: pixelCrop.width });
		}
		closeModal();
	};

	// console.log(crop);

	return (
		<Modal closeModal={closeModal}>
			<div className="form-block crop-block">
				<ReactCrop
					src={photo}
					crop={crop}
					onChange={setCrop}
					onComplete={(crop, percent) => {
						setPixelCrop(percent);
					}}
				/>
			</div>
			<div className="form-block crop-save-block">
				<button className="submit" onClick={submit}>
					Crop image
				</button>
			</div>
		</Modal>
	);
};

export default ImageCrop;
