import React from "react";
import ReactSelect from "react-select";
import ReactSelectAsync from "react-select/async";

const Select = props => {
	const height = props.small ? 40 : 50;

	const selectCustomStyles = {
		valueContainer: provided => ({
			...provided,
			height: height,
			padding: "2px 20px 2px 20px"
		}),
		container: provided => ({
			...provided
			// height: 50
		}),
		placeholder: provided => ({
			...provided,
			height: height,
			top: 10,
			transform: "none"
		}),
		input: provided => ({
			...provided,
			// height: height,
			color: "#fff",
			fontSize: "14px",
			position: "absolute",
			top: -2
		}),
		singleValue: provided => ({
			...provided,
			color: "#fff",
			fontSize: "14px",
			flex: 1,
			position: "unset",
			top: "auto",
			transform: "none",
			webkitTransform: "none",
			height: height,
			display: "flex",
			alignItems: "center"
		}),
		indicatorSeparator: provided => ({
			...provided,
			border: "0 none",
			backgroundColor: "#32373F",
			width: 0
		}),
		control: provided => ({
			...provided,
			background: props.small ? "none" : "#202328",
			// background: "#202328",
			color: "#fff",
			fontSize: "14px",
			border: "1px #525A66 solid",
			height: height,
			":before": {
				border: "1px #525A66 solid"
			},
			boxSizing: "border-box"
		}),
		option: (provided, { isSelected, isFocused }) => ({
			...provided,
			backgroundColor: isSelected || isFocused ? "#999" : "#32373F",
			color: "#fff",
			fontSize: "14px",
			lineHeight: height + "px",
			padding: "0 20px 0 20px",
			height: height,
			overflow: "hidden",
			textOverflow: "ellipsis"
		}),
		menu: provided => ({
			...provided,
			backgroundColor: "#32373F"
		})
	};

	let selectClassName = "react-select";
	const { className, async, ...restProps } = props;

	if (className) {
		selectClassName += " " + className;
	}

	if (async) {
		return <ReactSelectAsync styles={selectCustomStyles} className={selectClassName} {...restProps}></ReactSelectAsync>;
	} else {
		return <ReactSelect styles={selectCustomStyles} className={selectClassName} {...restProps}></ReactSelect>;
	}
};

export default Select;
