import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getSession } from "../../core/store/appSlice";
import { getUsers } from "../../core/store/usersSlice";
import { getMemberName } from "../../core/services/utils.service";

import A from "../../components/A";
import LoginForm from "./LoginForm";

const IndexPage = () => {
	const userSession = useSelector(state => getSession(state));
	const users = useSelector(state => getUsers(state));
	const user = userSession && userSession.user_id ? users[userSession.user_id] : null;

	const [showLogin, setShowLogin] = useState(false);

	const showLoginModal = async () => {
		setShowLogin(true);
	};

	const closeLoginModal = async () => {
		setShowLogin(false);
	};

	return (
		<>
			<header id="header" className="main-head">
				<div className="center">
					<strong className="logo">
						<div className="visual">
							<img src="/images/logo1_1.png" />
						</div>
						<div className="visual">
							<img src="/images/logo2_1.png" />
						</div>
						<span>
							International <br />
							Taekwon-Do Federation Database (ITF DB)
						</span>
					</strong>
					<div className="user-block">
						<div className="holder-lang">
							<div className="lang-holder">
								<ul className="lang">
									<li className="active">
										<A>
											<i className="ico">
												<img src="/images/br.svg" width="28" height="17" />
											</i>
											<span>ENG</span>
										</A>
									</li>
								</ul>
							</div>
						</div>
						{user && user.id ? (
							<Link to="/home">
								<button className="btn-logged btn-logged-on-index">
									<i className="icon-user">
										<img src="/images/icon_user.svg" />
									</i>
									<span>{getMemberName(user)}</span>
								</button>
							</Link>
						) : (
							<button className="btn-login" onClick={showLoginModal}>
								<i className="icon-user">
									<img src="/images/icon_user.svg" />
								</i>
								Login
							</button>
						)}
					</div>
				</div>
			</header>
			<main id="main">
				<section className="main-screen">
					<div className="center"></div>
				</section>
			</main>
			<footer id="footer" className="main-foot">
				<div className="center">
					<div className="footer-top">
						<div className="left-block">
							<div className="user-block">
								<div className="user">
									<img src="/images/user1.svg" />
								</div>
								<span className="user-name">Founder of Taekwon-Do - Gen. Choi Hong Hi</span>
							</div>
							<p>
								Taekwon‑Do was devised, studied and completed by Gen. Choi Hong Hi of Korea and brought into the world as modern martial arts.
								Gen. Choi Hong Hi had to practice Karate of Japan because Korea was under its colonial occupation for over 36 years. Korea was
								liberated from Japanese colonial rule in 1945.
							</p>
						</div>
						<div className="members-block">
							<h2>
								<span>ITF Сontinental Federations</span>
							</h2>
							<ul className="members-list">
								<li>
									<img src="/images/icon1.png" />
								</li>
								<li>
									<img src="/images/icon2.png" />
								</li>
								<li>
									<img src="/images/icon4.png" />
								</li>
								<li>
									<img src="/images/icon3.png" />
								</li>
								<li>
									<img src="/images/icon5.png" />
								</li>
							</ul>
						</div>
						<div className="right-block">
							<div className="user-block">
								<div className="user">
									<img src="/images/user2.svg" />
								</div>
								<span className="user-name">ITF President - Prof. RI Yong Son</span>
							</div>
							<p>
								Dear Members, I on behalf of the International Taekwon‑Do Federation (ITF) would like to extend my heartfelt thanks and
								congratulations to all the Taekwon‑Doists the world over who have been devoting themselves to the worldwide development and
								evolution of the original Taekwon‑Do.
							</p>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="copyright-block">
							<span className="copy">Copyright © All rights reserved. | ITF</span>
							<a target="_blank" className="privacy-policy" href="/ITF DB PRIVACY POLICY.pdf">
								PRIVACY POLICY
							</a>
							<ul className="companies-list">
								<li>
									<A>
										<img src="/images/company1.svg" />
									</A>
								</li>
								<li>
									<A>
										<img src="/images/company2.svg" />
									</A>
								</li>
							</ul>
						</div>
						<div className="members-block">
							<p className="feedback">
								For any technical matters, please, contact the ITF Online Platform Team at:{" "}
								<a href="mailto:support@taekwondo-itf.com">support@taekwondo-itf.com</a>
							</p>
						</div>
						<div className="companies-right-block">
							<ul className="companies-list">
								<li>
									<A>
										<img src="/images/company3.svg" />
									</A>
								</li>
								<li>
									<A>
										<img src="/images/company4.svg" />
									</A>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</footer>

			{showLogin && <LoginForm closeLoginModal={closeLoginModal} />}
		</>
	);
};

export default IndexPage;
