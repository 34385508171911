import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import IndexPage from "./pages/Index";
import HomePage from "./pages/Home";
import FederationsPage from "./pages/Federations";
import MembersPage from "./pages/Members";
import MemberPage from "./pages/Member";
import TestPage from "./pages/Test";
import FederationsDetails from "./pages/Federations/details";

function App() {
	return (
		<Switch>
			<Route path="/federations" exact component={FederationsPage} />
			<Route path="/federations/:id" exact component={FederationsDetails} />
			<Route path="/federations/childs/:id" component={FederationsPage} />
			<Route path="/members/:id" component={MembersPage} />
			<Route path="/member/:id" component={MemberPage} />
			<Route path="/home" exact component={HomePage} />
			<Route path="/tt" exact component={TestPage} />
			<Route path="/" exact component={IndexPage} />
			<Redirect from="*" to="/" />
		</Switch>
	);
}

export default App;
