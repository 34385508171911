import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import fecha from "fecha";
import DatePicker from "react-datepicker";

import { getDegreesOrdered, getInstructorsLevelsOptionsForCountry, getUmpiresLevelsOptionsForCountry } from "../../../core/store/dictionariesSlice";
import { addUser, loadUsersOptions, updateUser, uploadPhoto } from "../../../core/store/usersSlice";

import Select from "../../Select";
import { PhotoSelector } from "../PhotoSelector";
import debounce from "lodash.debounce";
import SocialMediaForm from "../SocialMediaForm";
import Modal from "../../Modal";
import FormikOnError from "../../FormikOnError";

const Genders = [
	{ value: "M", label: "Male" },
	{ value: "F", label: "Female" }
];

const AddMemberForm = ({ closeModal, club, editedUser }) => {
	const dispatch = useDispatch();
	const degrees = useSelector(state => getDegreesOrdered(state));
	const instructorsLevelsOptions = useSelector(state => getInstructorsLevelsOptionsForCountry(state));
	const umpiresLevelsOptions = useSelector(state => getUmpiresLevelsOptionsForCountry(state));

	const [socialMedia, setSocialMedia] = useState({});

	let title = "Individual Member Registration";
	let submitTitle = "Register";

	const initialValues = {
		given_name: "",
		family_name: "",
		fathers_name: "",
		n_given_name: "",
		n_family_name: "",
		n_fathers_name: "",
		sex: "M",
		birth: "",
		phone1: "",
		phone2: "",
		phone3: "",
		email: "",
		address: "",
		club_id: club ? club.id : editedUser.club_id,
		country: club ? club.country : editedUser.country,
		degree: -10
	};

	/* 	Photo */
	const [photoData, setPhotoData] = useState(null);
	/* end of photo */

	const loadDebouncedOptions = useCallback(
		debounce((inputValue, callback) => {
			loadUsersOptions(inputValue).then(options => callback(options));
		}, 300),
		[]
	);

	const isEdit = typeof editedUser !== "undefined";

	useEffect(() => {
		if (editedUser) {
			try {
				setSocialMedia(JSON.parse(editedUser.social_media));
			} catch (e) {}
		}
	}, [editedUser]);

	if (isEdit) {
		submitTitle = "Save";
		title = "Edit member";

		const excludeFields = [
			"dateadded",
			"can_test_up_to",
			"age",
			"federation_id",
			"head_federation_id",
			"continental_federation_id",
			"is_member",
			"member_id",
			"default_image",
			"coach_n_fullname",
			"firstcoach_n_fullname",
			"updated",
			"tournaments_not_member"
		];

		for (let n in editedUser) {
			if (n === "birth") {
				const birthDate = fecha.parse(editedUser[n], "DD.MM.YYYY");
				// initialValues[n] = fecha.format(birthDate, "YYYY-MM-DD");
				initialValues[n] = birthDate;
			} else if (excludeFields.indexOf(n) > -1) {
			} else {
				initialValues[n] = editedUser[n];
			}
		}
	}

	const validationSchema = {
		given_name: Yup.string().required("Required"),
		family_name: Yup.string().required("Required"),
		sex: Yup.string().required("Required"),
		birth: Yup.date().required("Required"),
		degree: Yup.number().required("Required"),
		email: Yup.string().email()
	};

	// const clubOptions = [{ value: club.id, label: club.name }];
	const degreesOptions = degrees.map(item => ({
		value: item.id,
		label: item.description
	}));

	const onSubmit = async (values, actions) => {
		if (typeof values.password !== "undefined" && !values.password.trim().length) {
			delete values.password;
		}

		values.social_media = JSON.stringify(socialMedia);

		const { coach_fullname, firstcoach_fullname, ...restValues } = values;

		const fields = {
			...restValues,
			birth: fecha.format(values.birth, "DD.MM.YYYY")
		};

		let result;

		if (isEdit) {
			delete fields.photo_force;
			const allFields = Object.assign({}, editedUser, fields);
			delete allFields.password;
			result = await dispatch(updateUser(fields, allFields));
		} else {
			result = await dispatch(addUser(fields));
		}

		if (result && result.error) {
			actions.setFieldError("general", result.error);
			actions.setSubmitting(false);
		} else {
			if (photoData) {
				await uploadImage(result);
			}
			closeModal();
		}
	};

	const uploadImage = async result => {
		let id;
		if (isEdit) {
			id = editedUser.id;
		} else {
			id = result.id;
		}

		const { crop, photo, imageSize } = photoData;
		const x = Math.round((crop.x * imageSize.width) / 100);
		const y = Math.round((crop.y * imageSize.height) / 100);
		const size = Math.round((crop.size * imageSize.width) / 100);
		await dispatch(uploadPhoto(photo.replace(/^data:image\/.*?;base64,/, ""), id, x, y, size));
	};

	return (
		<Modal closeModal={closeModal}>
			<Formik initialValues={initialValues} validationSchema={Yup.object().shape(validationSchema)} onSubmit={onSubmit}>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
					return (
						<form className="login-form" onSubmit={handleSubmit}>
							<FormikOnError>
								<fieldset>
									<h2>{title}</h2>
									<div className="form-block">
										<h3>Athletes (IDA)</h3>
										<div className="two-columns">
											<div className="col">
												<div className="form-row holder-error">
													<label>
														<span className="label">
															First name <sup>*</sup>
														</span>
														<input
															name="given_name"
															type="text"
															className={errors.given_name && touched.given_name ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.given_name}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">
															Last name <sup>*</sup>
														</span>
														<input
															name="family_name"
															type="text"
															className={errors.family_name && touched.family_name ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.family_name}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Patronymic</span>
														<input
															name="fathers_name"
															type="text"
															className={errors.fathers_name && touched.fathers_name ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.fathers_name}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">First name (national)</span>
														<input
															name="n_given_name"
															type="text"
															className={errors.n_given_name && touched.n_given_name ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.n_given_name}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Last name (national)</span>
														<input
															name="n_family_name"
															type="text"
															className={errors.n_family_name && touched.n_family_name ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.n_family_name}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Patronymic (national)</span>
														<input
															name="n_fathers_name"
															type="text"
															className={errors.n_fathers_name && touched.n_fathers_name ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.n_fathers_name}
														/>
													</label>
												</div>
												<div className="form-row columns date">
													<div className="half date-row">
														<label>
															<span className="label">
																Date of birth <sup>*</sup>
															</span>
															<DatePicker
																name="birth"
																selected={values.birth}
																dateFormat="dd.MM.yyyy"
																onChange={date => setFieldValue("birth", date)}
																className={errors.birth && touched.birth ? "error" : ""}
																showYearDropdown
																onBlur={handleBlur}
																shouldCloseOnSelect={true}
															/>
														</label>
													</div>
													<div className="half holder-error select-row">
														<label>
															<span className="label">
																Gender <sup>*</sup>
															</span>
															<Select
																name="sex"
																placeholder={""}
																options={Genders}
																className={errors.sex && touched.sex ? "error" : ""}
																onChange={selectedOption => setFieldValue("sex", selectedOption.value)}
																value={Genders ? Genders.find(option => option.value === values.sex) : ""}
															></Select>
														</label>
													</div>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Phone</span>
														<input
															name="phone1"
															type="text"
															className={errors.phone1 && touched.phone1 ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.phone1}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Phone</span>
														<input
															name="phone2"
															type="text"
															className={errors.phone2 && touched.phone2 ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.phone2}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Phone</span>
														<input
															name="phone3"
															type="text"
															className={errors.phone3 && touched.phone3 ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.phone3}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Email</span>
														<input
															name="email"
															type="text"
															className={errors.email && touched.email ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.email}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Address</span>
														<input
															name="address"
															type="text"
															className={errors.address && touched.address ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.address}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Coach</span>
														<Select
															name="coach"
															placeholder={""}
															async
															loadOptions={loadDebouncedOptions}
															className={errors.coach && touched.coach ? "error" : ""}
															onChange={selectedOption => setFieldValue("coach", selectedOption ? selectedOption.value : null)}
															defaultValue={{
																value: initialValues.coach,
																label: initialValues.coach_fullname
															}}
															isClearable={true}
														></Select>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">First coach</span>
														<Select
															name="firstcoach"
															placeholder={""}
															async
															loadOptions={loadDebouncedOptions}
															className={errors.firstcoach && touched.firstcoach ? "error" : ""}
															onChange={selectedOption =>
																setFieldValue("firstcoach", selectedOption ? selectedOption.value : null)
															}
															defaultValue={{
																value: initialValues.firstcoach,
																label: initialValues.firstcoach_fullname
															}}
															isClearable={true}
														></Select>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Instructor level</span>
														<Select
															name="instructor_level"
															placeholder={""}
															options={instructorsLevelsOptions(values.country)}
															className={errors.instructor_level && touched.instructor_level ? "error" : ""}
															onChange={selectedOption =>
																setFieldValue("instructor_level", selectedOption ? selectedOption.value : null)
															}
															value={
																instructorsLevelsOptions(values.country)
																	? instructorsLevelsOptions(values.country).find(
																			option => option.value === values.instructor_level
																	  )
																	: ""
															}
															isClearable={true}
														></Select>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Umpire level</span>
														<Select
															name="umpire_level"
															placeholder={""}
															options={umpiresLevelsOptions(values.country)}
															className={errors.umpire_level && touched.umpire_level ? "error" : ""}
															onChange={selectedOption =>
																setFieldValue("umpire_level", selectedOption ? selectedOption.value : null)
															}
															value={
																umpiresLevelsOptions(values.country)
																	? umpiresLevelsOptions(values.country).find(option => option.value === values.umpire_level)
																	: ""
															}
															isClearable={true}
														></Select>
													</label>
												</div>
												{/* <div className="form-row holder-error">
																	<label>
																		<span className="label">
																			Club <sup>*</sup>
																		</span>
																		<Select
																			name="club_id"
																			placeholder={""}
																			options={clubOptions}
																			className={errors.club_id && touched.club_id ? "error" : ""}
																			onChange={selectedOption => {
																				handleChange("club_id")(selectedOption.value);
																			}}
																			value={clubOptions ? clubOptions.find(option => option.value === values.club_id) : ""}
																			isDisabled={true}
																		></Select>
																	</label>
																</div> */}
												<div className="form-row holder-error">
													<label>
														<span className="label">
															GUP/DAN <sup>*</sup>
														</span>
														<Select
															name="degree"
															placeholder={""}
															options={degreesOptions}
															className={errors.degree && touched.degree ? "error" : ""}
															onChange={selectedOption => setFieldValue("degree", selectedOption.value)}
															value={degreesOptions ? degreesOptions.find(option => option.value === values.degree) : ""}
															// isDisabled={isEdit}
														></Select>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Password (leave blank to keep current password)</span>
														<input
															name="password"
															type="password"
															className={errors.password && touched.password ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.password}
														/>
													</label>
												</div>
												{errors.general && <div className="form-error-message">{errors.general}</div>}
												<input type="submit" value={isSubmitting ? "Loading..." : submitTitle} disabled={isSubmitting} />
											</div>
											<div className="col">
												<div className="form-row">
													<span className="label">Photo Athlete</span>
													<PhotoSelector
														setPhotoData={setPhotoData}
														initialPhoto={
															isEdit
																? `https://data.taekwondo-itf.com/user_${editedUser.id}_250.jpg?t=${editedUser.photo_force}`
																: null
														}
														typeFilter="image/jpeg"
													/>
												</div>
												<SocialMediaForm value={socialMedia} updateHandler={setSocialMedia} type="users" />
											</div>
										</div>
									</div>
								</fieldset>
							</FormikOnError>
						</form>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default AddMemberForm;
