import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import {
	addClub,
	addNationalFederation,
	FEDERATIONS_LEVELS,
	updateFederation,
	uploadLogo,
	clearFullPositions,
	clearPrivileges,
	reloadClub,
	getPrivileges
} from "../../../core/store/federationsSlice";
import { countriesList, employeePositions } from "../../../core/store/dictionariesSlice";
import { processPrivileges, processPositions } from "../../../core/store/usersSlice";

import Select from "../../Select";
import { PhotoSelector } from "../PhotoSelector";
import FederationFormPositions from "./FederationFormPositions";
import { array_diff } from "../../../core/services/utils.service";
import Accordition from "../../Accordition";
import FederationFormPrivileges from "./FederationFormPrivileges";
import SocialMediaForm from "../SocialMediaForm";
import Modal from "../../Modal";
import FormikOnError from "../../FormikOnError";

const ClubsLevels = [
	{ value: FEDERATIONS_LEVELS.Regional, label: "Regional federation" },
	{ value: FEDERATIONS_LEVELS.Club, label: "Club" }
];

const AddFederationFrom = ({ parent, closeModal, editedClub, parentFederation }) => {
	const dispatch = useDispatch();
	const countries = useSelector(state => countriesList(state));
	const employeePositionsForShow = useSelector(state => employeePositions(state));
	const privilegesList = useSelector(state => getPrivileges(state));

	const [positions, setPositions] = useState(null);
	const [prevPositions, setPrevPositions] = useState(null);
	const [privileges, setPrivileges] = useState(null);
	const [socialMedia, setSocialMedia] = useState({});

	const initialValues = {
		name: "",
		official_name: "",
		n_name: "",
		n_official_name: "",
		city: "",
		country: "",
		zip: "",
		address: "",
		website: ""
	};

	const [photoData, setPhotoData] = useState(null);

	const validationSchema = {
		name: Yup.string().required("Required"),
		official_name: Yup.string().required("Required"),
		city: Yup.string().required("Required"),
		country: Yup.string().required("Required")
	};

	let title = "NGB/Affiliate Registration";
	let submitTitle = "Register";
	let isNationalFederations = true;

	if (parentFederation && parentFederation.club_level >= FEDERATIONS_LEVELS.National) {
		title = "RGB or Club registration";
		isNationalFederations = false;
		validationSchema["club_level"] = Yup.string().required("Required");
		initialValues.club_level = null;
		initialValues.country = parentFederation.country;
	}

	useEffect(() => {
		if (editedClub) {
			try {
				setSocialMedia(JSON.parse(editedClub.social_media));
			} catch (e) {}
		}
	}, [editedClub]);

	if (editedClub) {
		if (editedClub.club_level === FEDERATIONS_LEVELS.Club) {
			title = "Edit club";
		} else {
			title = "Edit federation";
		}

		submitTitle = "Save";
		for (let n in initialValues) {
			if (editedClub[n]) {
				initialValues[n] = editedClub[n];
			}
		}
	}

	const countriesOptions = countries.map(item => ({ value: item[0], label: item[1] }));

	const onSubmit = async (values, actions) => {
		let result;

		// exclude
		const filteredValues = {};
		for (let n in values) {
			if (!n.startsWith("position_")) {
				filteredValues[n] = values[n];
			}
		}

		filteredValues.social_media = JSON.stringify(socialMedia);

		// general options
		if (editedClub) {
			const fields = Object.assign({}, editedClub, filteredValues);
			delete fields.level;
			delete fields.children_count;
			delete fields.childLoaded;
			delete fields.positions;
			delete fields.myPrivileges;
			delete fields.photo_force;
			delete fields.loading;

			// fields.social_media = JSON.stringify(fields.social_media);

			result = await dispatch(updateFederation(fields));
		} else {
			if (isNationalFederations) {
				result = await dispatch(addNationalFederation({ ...filteredValues, parent }));
			} else {
				result = await dispatch(addClub({ ...filteredValues, parent }));
			}
		}

		if (result && result.error) {
			actions.setFieldError("general", result.error);
			actions.setSubmitting(false);
		} else {
			// image
			if (photoData) {
				await uploadImage(result);
			}

			// positions
			if (editedClub) {
				await updatePositions();
				await updatePrivileges();
				await dispatch(reloadClub(editedClub.id));
			}

			closeModal();
		}
	};

	const updatePrivileges = async () => {
		if (privileges) {
			const prevPrivileges = {};

			privilegesList[editedClub.id].privileges.forEach(item => {
				if (typeof prevPrivileges[item.user_id] === "undefined") {
					prevPrivileges[item.user_id] = [];
				}
				prevPrivileges[item.user_id].push(item.privilege);
			});

			const actions = [];

			for (let user_id in privileges) {
				const curr = privileges[user_id];
				const prev = prevPrivileges[user_id] ?? [];
				const removed = array_diff(prev, curr);
				const added = array_diff(curr, prev);

				if (removed.length) {
					actions.push(["revoke", +user_id, editedClub.id, removed]);
				}
				if (added.length) {
					actions.push(["grant", +user_id, editedClub.id, added]);
				}
			}

			await dispatch(processPrivileges(actions));
			await dispatch(clearPrivileges(editedClub.id));
		}
	};

	const updatePositions = async () => {
		if (!positions) {
			return;
		}

		const actions = [];
		employeePositionsForShow.forEach(position => {
			if (position.max_count === 1) {
				if (prevPositions[position.id] === null && positions[position.id] !== null) {
					actions.push(["grant", positions[position.id].value, editedClub.id, position.id]);
				} else if (prevPositions[position.id] !== null && positions[position.id] === null) {
					actions.push(["revoke", prevPositions[position.id].value, editedClub.id, position.id]);
				} else if (
					prevPositions[position.id] !== null &&
					positions[position.id] !== null &&
					prevPositions[position.id].value !== positions[position.id].value
				) {
					actions.push(["revoke", prevPositions[position.id].value, editedClub.id, position.id]);
					actions.push(["grant", positions[position.id].value, editedClub.id, position.id]);
				}
			} else {
				const prev = prevPositions[position.id].map(item => item.value);
				const curr = positions[position.id].map(item => item.value);
				const removed = array_diff(prev, curr);
				const added = array_diff(curr, prev);
				if (removed.length) {
					actions.push(...removed.map(item => ["revoke", item, editedClub.id, position.id]));
				}
				if (added.length) {
					actions.push(...added.map(item => ["grant", item, editedClub.id, position.id]));
				}
			}
		});

		await dispatch(processPositions(actions));
		await dispatch(clearFullPositions(editedClub.id));
	};

	const uploadImage = async result => {
		let id;
		if (editedClub) {
			id = editedClub.id;
		} else {
			id = result.id;
		}

		const { crop, photo, imageSize } = photoData;
		const x = Math.round((crop.x * imageSize.width) / 100);
		const y = Math.round((crop.y * imageSize.height) / 100);
		const size = Math.round((crop.size * imageSize.width) / 100);
		await dispatch(uploadLogo(photo.replace(/^data:image\/.*?;base64,/, ""), id, x, y, size));
	};

	return (
		<Modal closeModal={closeModal}>
			<Formik initialValues={initialValues} validationSchema={Yup.object().shape(validationSchema)} onSubmit={onSubmit}>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
					return (
						<form className="login-form" onSubmit={handleSubmit}>
							<FormikOnError>
								<fieldset>
									<h2>{title}</h2>
									<div className="form-block">
										<div className="two-columns">
											<div className="col">
												{!isNationalFederations && (
													<div className="form-row holder-error">
														<label>
															<span className="label">
																Organization level <sup>*</sup>
															</span>
															<Select
																name="club_level"
																placeholder={""}
																options={ClubsLevels}
																className={errors.club_level && touched.club_level ? "error" : ""}
																onChange={selectedOption => setFieldValue("club_level", selectedOption.value)}
																value={ClubsLevels ? ClubsLevels.find(option => option.value === values.club_level) : ""}
																isDisabled={editedClub}
															></Select>
														</label>
													</div>
												)}
												<div className="form-row holder-error">
													<label>
														<span className="label">
															Short name <sup>*</sup>
														</span>
														<input
															name="name"
															type="text"
															className={errors.name && touched.name ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.name}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">
															Official name <sup>*</sup>
														</span>
														<input
															name="official_name"
															type="text"
															className={errors.official_name && touched.official_name ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.official_name}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Name (national)</span>
														<input
															name="n_name"
															type="text"
															className={errors.n_name && touched.n_name ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.n_name}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Official name (national)</span>
														<input
															name="n_official_name"
															type="text"
															className={errors.n_official_name && touched.n_official_name ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.n_official_name}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">
															Country <sup>*</sup>
														</span>
														<Select
															name="country"
															placeholder={""}
															options={countriesOptions}
															className={errors.country && touched.country ? "error" : ""}
															onChange={selectedOption => {
																handleChange("country")(selectedOption.value);
															}}
															value={countriesOptions ? countriesOptions.find(option => option.value === values.country) : ""}
															isDisabled={editedClub && editedClub.club_level > FEDERATIONS_LEVELS.National}
														></Select>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">
															City <sup>*</sup>
														</span>
														<input
															name="city"
															type="text"
															className={errors.city && touched.city ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.city}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Address</span>
														<input
															name="address"
															type="text"
															className={errors.address && touched.address ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.address}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">ZIP code</span>
														<input
															name="zip"
															type="text"
															className={errors.zip && touched.zip ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.zip}
														/>
													</label>
												</div>
											</div>
											<div className="col">
												<div className="form-row">
													<span className="label">Logo</span>
													<PhotoSelector
														setPhotoData={setPhotoData}
														initialPhoto={
															editedClub
																? `https://data.taekwondo-itf.com/club_${editedClub.id}_250.jpg?t=${editedClub.photo_force}`
																: null
														}
														typeFilter="image/png"
													/>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">Website</span>
														<input
															name="website"
															type="text"
															className={errors.website && touched.website ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.website}
														/>
													</label>
												</div>
												<SocialMediaForm value={socialMedia} updateHandler={setSocialMedia} type="clubs" />
											</div>
										</div>
										{editedClub && (
											<>
												<Accordition title={"Positions"}>
													<FederationFormPositions
														clubId={editedClub.id}
														updateHandler={setPositions}
														setPreviousPositions={setPrevPositions}
													/>
												</Accordition>
												<Accordition title={"Privileges"}>
													<FederationFormPrivileges clubId={editedClub.id} updateHandler={setPrivileges} />
												</Accordition>
											</>
										)}
										{errors.general && <div className="form-error-message">{errors.general}</div>}
										<input type="submit" value={isSubmitting ? "Loading..." : submitTitle} disabled={isSubmitting} />
									</div>
								</fieldset>
							</FormikOnError>
						</form>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default AddFederationFrom;
