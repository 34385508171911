import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getCertificatesTypes, CERTIFICATE_TYPES_ENUM, getInstructorsLevels, getUmpiresLevels } from "../../../core/store/dictionariesSlice";
import { loadUserCertificates, getCertificates, deleteUserCertificate } from "../../../core/store/usersSlice";

import A from "../../A";
import Loading from "../../Loading";
import Dan from "../Dan";
import AddCertificateForm from "./AddCertificateForm";

const Certificates = ({ user, country, allowEdit }) => {
	const dispatch = useDispatch();
	const certificatesTypes = useSelector(state => getCertificatesTypes(state));
	const certificates = useSelector(state => getCertificates(state));

	const instructorLevels = useSelector(state => getInstructorsLevels(state));
	const umpiresLevels = useSelector(state => getUmpiresLevels(state));

	const [selectedTab, setSelectedTab] = useState(CERTIFICATE_TYPES_ENUM["Gup certificate"]);
	const [firstSelectTab, setFirstSelectTab] = useState(true);

	const certificatesLoaded = typeof certificates[user] === "object" && Array.isArray(certificates[user]);

	useEffect(() => {
		if (user) {
			dispatch(loadUserCertificates(user));
		}
	}, [user]);

	useEffect(() => {
		if (
			firstSelectTab &&
			certificatesLoaded &&
			certificates[user].some(item => item.user_certificate_type_id === CERTIFICATE_TYPES_ENUM["Dan certificate"])
		) {
			setSelectedTab(CERTIFICATE_TYPES_ENUM["Dan certificate"]);
			setFirstSelectTab(false);
		}
	}, [certificatesLoaded]);

	const setCertificateType = useCallback(type => {
		setSelectedTab(type);
	}, []);

	// add form
	const [showAddForm, setShowAddForm] = useState(false);
	const openAddFrom = () => {
		setShowAddForm(true);
	};

	const closeAddFrom = () => {
		setShowAddForm(false);
	};

	if (!certificatesLoaded) {
		return <Loading />;
	}

	const userCertificates = {};
	Object.keys(certificatesTypes).forEach(item => {
		userCertificates[item] = certificates[user].filter(cert => cert.user_certificate_type_id === +item);
	});

	const deleteCertificate = id => {
		if (window.confirm("Are you sure to delete this?")) {
			dispatch(deleteUserCertificate(id, user));
		}
	};

	return (
		<>
			<div className="info-block">
				<h3>Certificates</h3>
				<ul className="content-nav">
					{Object.entries(certificatesTypes).map(item => (
						<li className={selectedTab === +item[0] ? "active" : ""} key={item[0]}>
							<A onClick={() => setSelectedTab(item[0])}>{item[1]}</A>
						</li>
					))}
				</ul>
				{userCertificates[selectedTab].length === 0 ? (
					<br />
				) : (
					<table className="certificates-table">
						<thead>
							<tr>
								{+selectedTab !== +CERTIFICATE_TYPES_ENUM["Recognition Plaque"] && <th>Rank</th>}
								<th>Certificate №</th>
								<th>Instructor</th>
								<th>Created Date</th>
								<th>Issued Date</th>
								{allowEdit && <th></th>}
							</tr>
						</thead>
						<tbody>
							{userCertificates[selectedTab].map((item, i) => (
								<tr key={item.certificate_number + "_" + i}>
									{+selectedTab !== +CERTIFICATE_TYPES_ENUM["Recognition Plaque"] && (
										<td>
											{(item.user_certificate_type_id === CERTIFICATE_TYPES_ENUM["Gup certificate"] ||
												item.user_certificate_type_id === CERTIFICATE_TYPES_ENUM["Dan certificate"]) && (
												<Dan degree={item.rank} withText style={{ alignItems: "flex-start" }} />
											)}
											{item.user_certificate_type_id === CERTIFICATE_TYPES_ENUM["Instructor certificate"] && instructorLevels[item.rank]}
											{item.user_certificate_type_id === CERTIFICATE_TYPES_ENUM["Umpire Certificate"] && umpiresLevels[item.rank]}
										</td>
									)}
									<td>{item.certificate_number}</td>
									<td>
										<Link to={`/member/${item.instructor_id}`}>{item.instructor_fullname}</Link>
										<Dan degree={item.instructor_degree} withText style={{ display: "inline-flex", marginLeft: 9 }} />
									</td>
									<td>{item.date_created}</td>
									<td>{item.date_issued}</td>
									{allowEdit && (
										<td>
											<button className="edit-button" title="Remove certificate" onClick={() => deleteCertificate(item.id)}>
												<img src="/images/remove.svg" width="20" height="20" />
											</button>
										</td>
									)}
								</tr>
							))}
						</tbody>
					</table>
				)}

				{allowEdit && (
					<>
						<br />
						<br />
						<div className="show-more-btn-holder admin-btn-holder">
							<button className="show-more" onClick={openAddFrom}>
								<img src="/images/add.svg" /> Add certificate
							</button>
						</div>
					</>
				)}
			</div>
			{showAddForm && <AddCertificateForm user={user} country={country} closeModal={closeAddFrom} setCertificateType={setCertificateType} />}
		</>
	);
};

export default Certificates;
