import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSocialMediaTypesForClubs, getSocialMediaTypesForUsers } from "../../core/store/dictionariesSlice";

const SocialMediaForm = ({ value, updateHandler, type }) => {
	const socialMediaTypeForUsers = useSelector(state => getSocialMediaTypesForUsers(state));
	const socialMediaTypeForClubs = useSelector(state => getSocialMediaTypesForClubs(state));

	const types = type === "clubs" ? socialMediaTypeForClubs : socialMediaTypeForUsers;
	const socialMediaTypes = types.filter(item => item.sm_type === "S");
	const messengersTypes = types.filter(item => item.sm_type === "M");

	const [socialMedia, setSocialMedia] = useState({});

	useEffect(() => {
		setSocialMedia(value);
	}, [value]);

	const updateValue = (id, value) => {
		const newState = { ...socialMedia };
		newState[id] = value;
		updateHandler(newState);
	};

	return (
		<>
			{socialMediaTypes && (
				<div className="form-row holder-error">
					<label>
						<span className="label">Social media</span>
						{socialMediaTypes.map(item => (
							<React.Fragment key={item.id}>
								<label>{item.id}</label>
								<input
									type="text"
									className="social-media-input"
									value={socialMedia[item.id] ?? ""}
									onChange={e => updateValue(item.id, e.target.value)}
								/>
							</React.Fragment>
						))}
					</label>
				</div>
			)}
			{messengersTypes.length > 0 && (
				<div className="form-row holder-error">
					<label>
						<span className="label">Messengers</span>
						{messengersTypes.map(item => (
							<React.Fragment key={item.id}>
								<label>{item.id}</label>
								<input
									type="text"
									className="social-media-input"
									value={socialMedia[item.id] ?? ""}
									onChange={e => updateValue(item.id, e.target.value)}
								/>
							</React.Fragment>
						))}
					</label>
				</div>
			)}
		</>
	);
};

export default SocialMediaForm;
