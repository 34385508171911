import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { reduxBatch } from "@manaflair/redux-batch";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { history } from "../services/history.service";
import { createInstance } from "../services/errors.service";
import { reducer as toastrReducer } from "react-redux-toastr";

import appReducer, { APP_REDUCER_KEY, initApp } from "./appSlice";
import dictionariesPageReducer, { DICTIONARIES_PAGE_REDUCER_KEY } from "./dictionariesSlice";
import federationsPageReducer, { FEDERATIONS_PAGE_REDUCER_KEY } from "./federationsSlice";
import usersPageReducer, { USERS_PAGE_REDUCER_KEY } from "./usersSlice";

const rootReducer = combineReducers({
	router: connectRouter(history),
	toastr: toastrReducer,
	[APP_REDUCER_KEY]: appReducer,
	pages: combineReducers({
		[DICTIONARIES_PAGE_REDUCER_KEY]: dictionariesPageReducer,
		[FEDERATIONS_PAGE_REDUCER_KEY]: federationsPageReducer,
		[USERS_PAGE_REDUCER_KEY]: usersPageReducer
	})
});

const store = configureStore({
	reducer: rootReducer,
	middleware: [...getDefaultMiddleware(), routerMiddleware(history)],
	enhancers: [reduxBatch]
});

createInstance(store.dispatch);
store.dispatch(initApp());

export default store;
