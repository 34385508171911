import React from "react";

const Loading = ({ dark }) => {
	let imagePath = "/images/loader.gif";
	if (dark) {
		imagePath = "/images/loader-dark.gif";
	}

	return (
		<div className="loader">
			<img src={imagePath} width="40" height="40" />
		</div>
	);
};

export default Loading;
