export default class LocalStorageService {
	static getItem(name) {
		let result;

		const value = window.localStorage.getItem(name);

		if (value) {
			result = value;
			if (typeof value === "string") {
				try {
					result = JSON.parse(value);
				} catch (err) {}
			}
		}

		return result;
	}

	static setItem(name, value) {
		if (typeof value === "object") {
			value = JSON.stringify(value);
		}
		window.localStorage.setItem(name, value);
	}
}
