import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
	federationsList as federationsListSelector,
	federations as federationsSelector,
	loadChilds,
	isLoaded,
	getClubBreadcrumbs,
	ROOT_CLUB_ID,
	deleteClub
} from "../../core/store/federationsSlice";

import { PRIVILEGES_LEVELS } from "../../core/store/usersSlice";

import Layout from "../../components/Layout/Layout";
import Club from "../../components/Blocks/Club/Club";
import AddFederationFrom from "../../components/Blocks/AddFederationForm/AddFederationForm";
import Loading from "../../components/Loading";
import { getClubName } from "../../core/services/utils.service";

const FederationsPage = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const federationsLoaded = useSelector(state => isLoaded(state));
	const federations = useSelector(state => federationsSelector(state));
	const federationsList = useSelector(state => federationsListSelector(state));
	let listForShow = id ? federationsList.filter(item => item.parent === +id) : federationsList.filter(item => item.parent === id);
	listForShow.sort((a, b) => getClubName(a).localeCompare(getClubName(b)));

	useEffect(() => {
		if (federationsLoaded && id) {
			dispatch(loadChilds(id));
		}
	}, [id, federationsLoaded]);

	const [breadcrumbs, setBreadcrumbs] = useState([]);

	const loadBreadcrumbs = async id => {
		const b = await dispatch(getClubBreadcrumbs(id, true));
		setBreadcrumbs(b);
	};

	useEffect(() => {
		loadBreadcrumbs(id);
	}, [federations, id]);

	let pageTitle = null;

	const selectedFederation = federations[id];
	if (selectedFederation) {
		pageTitle = `${getClubName(selectedFederation)} branches`;
	}

	if (!pageTitle) {
		pageTitle = "List of Federations";
	}

	// add form
	const [showAddForm, setShowAddForm] = useState(false);

	const openAddFrom = id => {
		setEditedClub(null);
		setShowAddForm(+id);
	};

	const closeAddFrom = () => {
		setShowAddForm(false);
		setEditedClub(null);
	};

	// edit form
	const [editedClub, setEditedClub] = useState(null);
	const editFederation = club => {
		setEditedClub(club);
		setShowAddForm(true);
	};

	const loading = id && (!selectedFederation || !selectedFederation.childLoaded);

	let allowEdit = selectedFederation && selectedFederation.myPrivileges && selectedFederation.myPrivileges.indexOf(PRIVILEGES_LEVELS.ClubEdit) > -1;

	if (!id) {
		allowEdit =
			federations[ROOT_CLUB_ID] &&
			federations[ROOT_CLUB_ID].myPrivileges &&
			federations[ROOT_CLUB_ID].myPrivileges.indexOf(PRIVILEGES_LEVELS.ClubEdit) > -1;
	}

	const deleteClubClicked = id => {
		if (window.confirm("Are you sure to delete this?")) {
			dispatch(deleteClub(id));
		}
	};

	return (
		<>
			<Layout breadcrumbs={breadcrumbs} title={pageTitle}>
				<div className="info-wrapper-blocks">
					{id && selectedFederation && allowEdit && (
						<div className="show-more-btn-holder admin-btn-holder">
							<button className="show-more" onClick={() => openAddFrom(id)}>
								<img src="/images/add.svg" /> Add federation
							</button>
						</div>
					)}
					<div className="two-columns">
						{loading && <Loading />}
						{!loading && listForShow.length > 0 && (
							<>
								<div className="col-half">
									{Array.isArray(listForShow) &&
										listForShow
											// .filter((item, i) => i % 2 === 0)
											.map(item => (
												<Club
													key={item.id}
													club={item}
													editFederation={allowEdit ? editFederation : null}
													addChildFederation={id => openAddFrom(id)}
													showUsersButton
													deleteClub={deleteClubClicked}
												/>
											))}
								</div>
								{/* <div className="col-half">
									{Array.isArray(listForShow) &&
										listForShow
											.filter((item, i) => i % 2 !== 0)
											.map(item => (
												<Club
													key={item.id}
													club={item}
													showChildsButton={item.children_count > 0}
													editFederation={allowEdit ? editFederation : () => {}}
													// addChildFederation={id ? id => openAddFrom(id) : null}
													addChildFederation={id => openAddFrom(id)}
													showUsersButton
													deleteClub={deleteClubClicked}
												/>
											))}
								</div> */}
							</>
						)}
						{!loading && listForShow.length === 0 && <div>No child federations</div>}
					</div>
				</div>
			</Layout>
			{showAddForm && (
				<AddFederationFrom closeModal={closeAddFrom} parent={showAddForm} editedClub={editedClub} parentFederation={federations[showAddForm]} />
			)}
		</>
	);
};

export default FederationsPage;
