import React from "react";
import { useSelector } from "react-redux";

const Dan = ({ degree, withText, style }) => {
	const degrees = useSelector(state => state.pages.dictionaries.degrees);

	if (!degrees) {
		return <></>;
	}

	const userDegree = degrees[degree];
	if (!userDegree) {
		return <></>;
	}

	if (withText) {
		if (degree < 0) {
			return (
				<div className="dan-block" style={style}>
					<div className="dan-description">{userDegree.description}</div>
					<div className={`dan gup${Math.abs(degree)}`}></div>
				</div>
			);
		} else {
			return (
				<div className="dan-block" style={style}>
					<div className="dan-description">{userDegree.description}</div>
					<div className={`dan dan-n`}>
						<span>{userDegree.name}</span>
					</div>
				</div>
			);
		}
	} else {
		if (degree < 0) {
			return <div className={`dan gup${Math.abs(degree)}`} style={style}></div>;
		} else {
			return (
				<div className={`dan dan-n`} style={style}>
					<span>{userDegree.name}</span>
				</div>
			);
		}
	}
};

export default Dan;
