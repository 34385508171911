import { logout } from "../store/appSlice";

class ErrorService {
	constructor(dispatch) {
		this.dispatch = dispatch;
	}

	processError(error) {
		if (error === " Invalid or expired session" || error === " Invalid signature") {
			this.dispatch(logout());
		}
		return error;
	}

	logout() {
		this.dispatch(logout());
	}
}

let instance;
export function createInstance(dispatch) {
	instance = new ErrorService(dispatch);
}

export const getInstance = () => instance;
