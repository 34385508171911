import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSession } from "../../core/store/appSlice";

import Layout from "../../components/Layout/Layout";
import Member from "../../components/Blocks/Member/Member";
import { federations, loadUserClubs } from "../../core/store/federationsSlice";
import { getUsers } from "../../core/store/usersSlice";
import Loading from "../../components/Loading";

const HomePage = () => {
	const dispatch = useDispatch();
	const userSession = useSelector(state => getSession(state));
	const users = useSelector(state => getUsers(state));
	const clubs = useSelector(state => federations(state));

	const user = userSession ? users[userSession.user_id] : null;

	const [userClubsIds, setUserClubsIds] = useState([]);

	const loadHomeUserClubs = async () => {
		const clubIds = await dispatch(loadUserClubs(userSession.user_id));
		setUserClubsIds(clubIds);
	};

	useEffect(() => {
		if (userSession.user_id) {
			loadHomeUserClubs();
		}
	}, [user]);

	///const club = typeof user === "object" && user !== null && typeof clubs[user.club_id] === "object" && clubs[user.club_id].id ? clubs[user.club_id] : null;

	const userClubs = userClubsIds.map(item => clubs[item]);
	
	return <Layout>{typeof user !== "object" && user !== null ? <Loading /> : <Member user={user} clubs={userClubs} />}</Layout>;
};

export default HomePage;
