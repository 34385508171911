import React, { useCallback } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import debounce from "lodash.debounce";

import { loadUsersOptions } from "../../../../core/store/usersSlice";

import FormikOnError from "../../../FormikOnError";
import Select from "../../../Select";
import { addScheduleUsers } from "../../../../core/store/federationsSlice";

const validationSchema = {
	user_id: Yup.number().required("Required")
};

const AddScheduleMemberForm = ({ scheduleId }) => {
	const dispatch = useDispatch();

	const initialValues = {
		user_id: null,
		schedule: ""
	};

	const onSubmit = async (values, actions) => {
		const fields = {
			schedule_id: scheduleId,
			user_id: values.user_id
		};

		const result = await dispatch(addScheduleUsers(fields));

		if (result && result.error) {
			actions.setFieldError("general", result.error);
			actions.setSubmitting(false);
		} else {
			actions.resetForm();
		}
	};

	const loadDebouncedOptions = useCallback(
		debounce((inputValue, callback) => {
			loadUsersOptions(inputValue).then(options => callback(options));
		}, 300),
		[]
	);

	return (
		<Formik initialValues={initialValues} validationSchema={Yup.object().shape(validationSchema)} onSubmit={onSubmit}>
			{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
				return (
					<form className="search-form" id="schedule-member-form" onSubmit={handleSubmit}>
						<FormikOnError>
							<div className="form-row holder-error">
								<Select
									name="user_id"
									small
									placeholder="Member"
									async
									loadOptions={loadDebouncedOptions}
									className={errors.user_id && touched.user_id ? "error" : ""}
									onChange={selectedOption => setFieldValue("user_id", selectedOption ? selectedOption.value : null)}
									onBlur={handleBlur}
								></Select>
							</div>
							{errors.general && <div className="form-error-message">{errors.general}</div>}
							<input type="submit" value={isSubmitting ? "Loading..." : "Add"} disabled={isSubmitting} />
						</FormikOnError>
					</form>
				);
			}}
		</Formik>
	);
};

export default AddScheduleMemberForm;
