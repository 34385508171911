import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import A from "../../components/A";
import { login } from "../../core/store/appSlice";

const LoginValidationSchema = Yup.object().shape({
	login: Yup.string().required("Required"),
	password: Yup.string().required("Required")
});

const LoginForm = ({ closeLoginModal }) => {
	const dispatch = useDispatch();

	return (
		<div className="modal__holder popup-login modal-holder open" id="popup-login">
			<div className="modal-inner">
				<div className="holder-popup">
					<div className="bg js-close-modal close-modal">&nbsp;</div>
					<div className="modal__container modal">
						<Formik
							initialValues={{ login: "", password: "" }}
							validationSchema={LoginValidationSchema}
							onSubmit={async (values, actions) => {
								const result = await dispatch(login(values.login, values.password));
								if (result && result.error) {
									actions.setFieldError("general", result.error);
								}
							}}
						>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
								<form className="login-form" onSubmit={handleSubmit}>
									<fieldset>
										<h3>User login</h3>
										<div className="form-row">
											<label htmlFor="alt01">Name</label>
											<input
												id="alt01"
												type="text"
												name="login"
												className={errors.login && touched.login ? "error" : ""}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.login}
											/>
										</div>
										<div className="form-row">
											<label htmlFor="alt02">Password</label>
											<input
												id="alt02"
												type="password"
												name="password"
												className={errors.password && touched.password ? "error" : ""}
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.password}
											/>
										</div>
										<div className="form-check-row">
											{/* <div className="flex-item">
												<div className="flex-box">
													<input id="alt03" type="checkbox" className="check" />
													<label htmlFor="alt03">Save password</label>
												</div>
											</div> */}
											<A className="forgot-password">Forgot your password?</A>
										</div>
										{errors.general && <div className="form-error-message">{errors.general}</div>}
										<input type="submit" value="Apply" disabled={isSubmitting} />
									</fieldset>
								</form>
							)}
						</Formik>
						<A onClick={closeLoginModal} className="modal__close-ico js-close-modal close-modal">
							<img src="/images/close.svg" />
						</A>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginForm;
