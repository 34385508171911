import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Pagination from "rc-pagination";

import { federations as federationsSelector, getClubBreadcrumbs, loadClub } from "../../core/store/federationsSlice";
import { deleteUser, getList, loadClubUsers, PRIVILEGES_LEVELS } from "../../core/store/usersSlice";

import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import A from "../../components/A";
import Dan from "../../components/Blocks/Dan";
import AddMemberForm from "../../components/Blocks/Member/AddMemberForm";
import { formatAge, getMemberName } from "../../core/services/utils.service";

const USERS_PAGE_SIZE = process.env.REACT_APP_USERS_PAGE_SIZE;

const MembersPage = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const federations = useSelector(state => federationsSelector(state));
	const countries = useSelector(state => state.pages.dictionaries.countries);
	const usersByClub = useSelector(state => getList(state));

	const [currentPage, setCurrentPage] = useState(1);

	const members = usersByClub[id];

	const club = federations[id];

	useEffect(() => {
		dispatch(loadClub(id));
		// return () => {
		// 	dispatch(unloadClubUsers(id));
		// };
	}, [id]);

	useEffect(() => {
		dispatch(loadClubUsers(id, false, currentPage));
	}, [currentPage]);

	// add form
	const [showAddForm, setShowAddForm] = useState(false);
	const openAddFrom = () => {
		setShowAddForm(true);
	};

	const closeAddFrom = () => {
		setShowAddForm(false);
	};

	const pageItemRender = (current, type, element) => {
		if (type === "page") {
			return <A>{current}</A>;
		} else if (type === "next" || type === "prev") {
			return (
				<A>
					<span></span>
				</A>
			);
		} else if (type === "jump-next" || type === "jump-prev") {
			return <A>...</A>;
		}
	};

	const [breadcrumbs, setBreadcrumbs] = useState([]);

	const loadBreadcrumbs = async id => {
		const b = await dispatch(getClubBreadcrumbs(id));
		setBreadcrumbs(b);
	};

	useEffect(() => {
		if (club && club.id) {
			loadBreadcrumbs(club.id);
		}
	}, [federations]);

	const deleteUserClicked = async id => {
		if (window.confirm("Are you sure to delete this user?")) {
			dispatch(deleteUser(id, club.id));
		}
	};

	const allowShowMembers = club && club.myPrivileges && club.myPrivileges.indexOf(PRIVILEGES_LEVELS.PersonalEdit) > -1;

	return (
		<>
			<Layout title="List of club members" breadcrumbs={breadcrumbs}>
				{!club || !members || !Array.isArray(members.users) ? (
					<Loading />
				) : (
					<div className="info-wrapper-blocks">
						{allowShowMembers && (
							<div className="show-more-btn-holder admin-btn-holder">
								<button className="show-more" onClick={openAddFrom}>
									<img src="/images/add.svg" /> Add member
								</button>
							</div>
						)}

						<div className="club-block response-hidden">
							<div className="box">
								<div className="country">
									<div className="visual-flag">
										{club.country && <img src={`/images/flags/${club.country.toLowerCase()}.svg`} height="140" />}
									</div>
									<span className="country-name">{countries[club.country]}</span>
								</div>
							</div>
							<div className="box">
								<div className="logo-block">
									<div className="logo">
										{/* <img src={`https://data.taekwondo-itf.com/club_${id}_100.jpg`} /> */}
										<img src="/images/empty-logo.png" />
									</div>
									<span className="name">{club.name}</span>
								</div>
							</div>
						</div>

						{allowShowMembers ? (
							<div className="col-club">
								<div className="info-block table">
									<div className="search-form-club">
										<header className="head">
											<span className="title">List of Members</span>
											<span className="result-count">
												Total members - <span className="count">{members.usersCount}</span>
											</span>
										</header>
									</div>
									<table className="members-table">
										<thead>
											<tr>
												<th>
													<div>Name</div>
												</th>
												<th>
													<div>Age</div>
												</th>
												<th>
													<div>Gender</div>
												</th>
												<th>
													<div>Rank</div>
												</th>
												<th>
													<div>&nbsp;</div>
												</th>
											</tr>
										</thead>
										<tbody>
											{members.users.map(user => (
												<tr key={user.id}>
													<td>
														<div>
															<Link to={`/member/${user.id}`}>{getMemberName(user)}</Link>
														</div>
													</td>
													<td>
														<div>{formatAge(user.age)}</div>
													</td>
													<td>
														<div>{user.sex === "M" ? "Male" : "Female"}</div>
													</td>
													<td>
														<div>
															<Dan degree={user.degree} />
														</div>
													</td>
													<td>
														<div className="remove-td">
															<A onClick={() => deleteUserClicked(user.id)}>
																<img src="/images/remove.svg" width="20" height="20" />
															</A>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<Pagination
									className="pagination"
									total={members.usersCount}
									pageSize={USERS_PAGE_SIZE}
									currentPage={currentPage}
									onChange={setCurrentPage}
									locale={"en_US"}
									itemRender={pageItemRender}
								/>
							</div>
						) : (
							<div>You have no permissions</div>
						)}
					</div>
				)}
			</Layout>
			{showAddForm && <AddMemberForm closeModal={closeAddFrom} club={club} />}
		</>
	);
};

export default MembersPage;
