import { createSelector, createSlice } from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";

import ApiService from "../services/api.service";
import { getClubName } from "../services/utils.service";

export const FEDERATIONS_PAGE_REDUCER_KEY = "federations";
export const ROOT_CLUB_ID = process.env.REACT_APP_ROOT_CLUB_ID;
export const FEDERATIONS_LEVELS = Object.freeze(JSON.parse(process.env.REACT_APP_CLUBS_LEVEL));

const initialState = {
	list: {},
	loading: false,
	loaded: false,
	positions: {},
	privileges: {},
	gyms: {}
};

const federationsPageSlice = createSlice({
	name: FEDERATIONS_PAGE_REDUCER_KEY,
	initialState,
	reducers: {
		startLoading: state => {
			state.loading = true;
		},
		setList: (state, { payload }) => {
			for (let n in payload) {
				state.list[n] = payload[n];
			}
			state.loading = false;
			state.loaded = true;
		},
		appendToList: (state, { payload }) => {
			payload.forEach(item => {
				state.list[item.id] = item;
			});
		},
		setChildsLoading: (state, { payload }) => {
			state.list[payload].loading = true;
		},
		setChildsLoaded: (state, { payload }) => {
			state.list[payload].childLoaded = true;
			delete state.list[payload].loading;
		},
		increaseChildsCount: (state, { payload }) => {
			if (state.list[payload] && typeof state.list[payload].children_count !== "undefined") {
				state.list[payload].children_count++;
			}
		},
		updateListItem: (state, { payload }) => {
			state.list[payload.id] = Object.assign({}, state.list[payload.id], payload, { loading: false });
		},
		setClubLoading: (state, { payload }) => {
			state.list[payload] = { loading: true };
		},
		setClub: (state, { payload }) => {
			state.list[payload.id] = { ...payload };
		},
		resetState: state => {
			state = initialState;
		},
		startLoadPositions: (state, { payload: id }) => {
			state.positions[id] = "loading";
		},
		setFullPositions: (state, { payload: { id, positions } }) => {
			state.positions[id] = positions;
		},
		clearFullPositions: (state, { payload: id }) => {
			delete state.positions[id];
		},
		startLoadPrivileges: (state, { payload: id }) => {
			state.privileges[id] = "loading";
		},
		setPrivileges: (state, { payload: { id, privileges } }) => {
			state.privileges[id] = privileges;
		},
		clearPrivileges: (state, { payload: id }) => {
			delete state.privileges[id];
		},
		deleteClub: (state, { payload: id }) => {
			delete state.list[id];
		},
		startLoadGyms: (state, { payload: id }) => {
			state.gyms[id] = "loading";
		},
		setGyms: (state, { payload: { id, gyms } }) => {
			state.gyms[id] = gyms;
		},
		clearGyms: (state, { payload: id }) => {
			delete state.gyms[id];
		}
	}
});

export const { clearFullPositions, clearPrivileges } = federationsPageSlice.actions;

// selectors
const getFederationsState = state => state.pages[FEDERATIONS_PAGE_REDUCER_KEY];
export const isLoading = state => getFederationsState(state).loading;
export const isLoaded = state => getFederationsState(state).loaded;
export const initiated = createSelector([isLoading, isLoaded], (loading, loaded) => {
	return loading || loaded;
});
export const federations = state => getFederationsState(state).list;
export const federationsList = createSelector([federations], federations => {
	return Object.values(federations);
});
export const getFullpositions = state => getFederationsState(state).positions;
export const getPrivileges = state => getFederationsState(state).privileges;

export const getGyms = state => getFederationsState(state).gyms;

// actions
export const loadMainFederations = (force = false) => async (dispatch, getState) => {
	const state = getState();
	const isInitiated = initiated(state);

	if (isInitiated && !force) {
		return;
	}

	dispatch(federationsPageSlice.actions.startLoading());

	let { clubs: federations } = await ApiService.getClubs(ROOT_CLUB_ID, 2);

	if (federations) {
		const federationsMap = {};
		federations.forEach(item => {
			const { level, positions, ...rest } = item;

			const positionsHash = {};
			positions.forEach(pos => {
				positionsHash[pos.position_id] = pos;
			});

			federationsMap[item.id] = {
				...rest,
				positions: positionsHash,
				childLoaded: !item.parent
			};
		});

		dispatch(federationsPageSlice.actions.setList(federationsMap));
	} else {
		dispatch(federationsPageSlice.actions.resetState());
	}
};

export const addNationalFederation = values => async dispatch => {
	const result = await ApiService.addClub({ ...values, club_level: FEDERATIONS_LEVELS.National });

	const { id } = result;

	if (id) {
		const { clubs } = await ApiService.getClubs(id, 1);
		dispatch(federationsPageSlice.actions.appendToList(clubs));
		dispatch(federationsPageSlice.actions.increaseChildsCount(values.parent));
	}

	return result;
};

export const addClub = values => async dispatch => {
	const result = await ApiService.addClub({ ...values });

	const { id } = result;

	if (id) {
		const { clubs } = await ApiService.getClubs(id, 1);

		dispatch(federationsPageSlice.actions.appendToList(clubs));
		dispatch(federationsPageSlice.actions.increaseChildsCount(values.parent));
	}

	return result;
};

export const updateFederation = values => async dispatch => {
	const result = await ApiService.updateClub(values);

	if (result && !result.error) {
		dispatch(federationsPageSlice.actions.updateListItem(values));
	}
	return result;
};

export const loadChilds = (id, levels = 2) => async (dispatch, getState) => {
	const state = getFederationsState(getState());

	const parentLoaded = typeof state.list[id] !== "undefined";

	if (parentLoaded && (state.list[id].childLoaded || state.list[id].loading)) {
		return;
	}

	if (parentLoaded) {
		dispatch(federationsPageSlice.actions.setChildsLoading(id));
	}

	let { clubs } = await ApiService.getClubs(id, levels);

	if (parentLoaded) {
		clubs = clubs.filter(item => item.level === 2);
	}

	clubs = clubs.map(item => {
		const { level, positions, ...rest } = item;

		const positionsHash = {};
		positions.forEach(pos => {
			positionsHash[pos.position_id] = pos;
		});

		return {
			...rest,
			positions: positionsHash,
			childLoaded: item.level === 1
		};
	});

	dispatch(federationsPageSlice.actions.appendToList(clubs));
	dispatch(federationsPageSlice.actions.setChildsLoaded(id));
};

export const loadClub = (id, force = false) => async (dispatch, getState) => {
	const state = getFederationsState(getState());

	if (typeof state.list[id] !== "undefined" && !force) {
		return;
	}

	dispatch(federationsPageSlice.actions.setClubLoading(id));

	let { clubs } = await ApiService.getClubs(id, 1);

	const club = clubs[0];

	if (club) {
		delete club.level;

		if (club.positions) {
			const positionsHash = {};
			club.positions.forEach(pos => {
				positionsHash[pos.position_id] = pos;
			});
			club.positions = positionsHash;
		}

		if (force) {
			club.photo_force = +new Date();
		}

		if (force) {
			dispatch(federationsPageSlice.actions.updateListItem(club));
		} else {
			dispatch(federationsPageSlice.actions.setClub(club));
		}
	}
};

export const loadUserClubs = userId => async dispatch => {
	let { clubs } = await ApiService.getUserClubs(userId);
	dispatch(federationsPageSlice.actions.appendToList(clubs));

	return clubs.map(item => item.id);
};

export const reloadClub = id => async dispatch => {
	await dispatch(loadClub(id, true));
};

export const loadParents = (id, exceptId) => async (dispatch, getState) => {
	const state = getFederationsState(getState());

	if (typeof state.list[id] !== "undefined") {
		return;
	}

	let { clubs } = await ApiService.getParentsClubs(id, 20);

	if (clubs) {
		clubs = clubs.filter(item => item.id !== exceptId);

		clubs = clubs.map(item => {
			const { level, positions, ...rest } = item;

			const result = {
				...rest,
				childLoaded: false
			};

			if (positions) {
				const positionsHash = {};
				positions.forEach(pos => {
					positionsHash[pos.position_id] = pos;
				});
				result.positions = positionsHash;
			}

			return result;
		});

		dispatch(federationsPageSlice.actions.appendToList(clubs));
	}
};

export const uploadLogo = (imgData, id, x, y, size) => async dispatch => {
	await ApiService.uploadPicture(imgData, "clubs", id, x, y, size);
	//dispatch(usersPageSlice.actions.userSetPhotoForce(id));
};

export const getClubBreadcrumbs = (id, showChilds) => async (dispatch, getState) => {
	const fList = federations(getState());

	let pageUrl = "/federations/";
	if (showChilds) {
		pageUrl = "/federations/childs/";
	}

	let path = [];
	let parent = id;

	while (parent) {
		const parentFederation = fList[+parent];
		if (parentFederation) {
			path.push([getClubName(parentFederation), `${pageUrl}${parent}`]);
			parent = parentFederation.parent;
		} else {
			dispatch(loadParents(+parent, +id));
			parent = null;
		}
	}

	let breadcrumbs = [];

	if (path.length) {
		path.reverse();
		breadcrumbs.push(...path);
	}

	return breadcrumbs;
};

export const loadFullClubPositions = id => async (dispatch, getState) => {
	const state = getFullpositions(getState());

	if (typeof state[id] !== "undefined") {
		return;
	}

	dispatch(federationsPageSlice.actions.startLoadPositions(id));

	const positions = await ApiService.GetUsersClubsPositionsForClub(id);
	const positionsHash = {};

	positions.positions.forEach(item => {
		if (positions.users[item.user_id]) {
			if (typeof positionsHash[item.position_id] === "undefined") {
				positionsHash[item.position_id] = [];
			}

			const phones = [];
			for (let i = 1; i <= 3; i++) {
				if (typeof positions.users[item.user_id][`phone${i}`] !== "undefined") {
					phones.push(positions.users[item.user_id][`phone${i}`]);
				} else {
					break;
				}
			}

			positionsHash[item.position_id].push({
				user_id: item.user_id,
				position_id: item.position_id,
				given_name: positions.users[item.user_id].given_name,
				family_name: positions.users[item.user_id].family_name,
				n_given_name: positions.users[item.user_id].n_given_name,
				n_family_name: positions.users[item.user_id].n_family_name,
				phones
			});
		}
	});

	dispatch(federationsPageSlice.actions.setFullPositions({ id, positions: positionsHash }));
};

export const loadClubPrivileges = id => async (dispatch, getState) => {
	const state = getPrivileges(getState());

	if (typeof state[id] !== "undefined") {
		return;
	}

	dispatch(federationsPageSlice.actions.startLoadPrivileges(id));

	const privileges = await ApiService.GetClubsPriveleges(id);

	dispatch(federationsPageSlice.actions.setPrivileges({ id, privileges }));
};

export const deleteClub = id => async dispatch => {
	const result = await ApiService.DeleteClub(id);

	if (result && !result.error) {
		dispatch(federationsPageSlice.actions.deleteClub(id));
	} else if (result && result.error) {
		toastr.error(result.error);
	}

	return result;
};

// gyms
export const loadClubGyms = (id, force) => async (dispatch, getState) => {
	const state = getGyms(getState());

	if (typeof state[id] !== "undefined" && !force) {
		return;
	}

	dispatch(federationsPageSlice.actions.startLoadGyms(id));

	const { gyms } = await ApiService.GetGyms(id);

	dispatch(federationsPageSlice.actions.setGyms({ id, gyms }));
};

export const addClubGym = values => async dispatch => {
	const result = await ApiService.AddGym({ ...values });

	if (result && !result.error) {
		dispatch(loadClubGyms(result.id, true));
	}

	return result;
};

export const updateClubGym = (values, clubId) => async dispatch => {
	const result = await ApiService.UpdateGym({ ...values });

	if (result && !result.error) {
		dispatch(loadClubGyms(clubId, true));
	}

	return result;
};

export const deleteClubGym = (id, clubId) => async dispatch => {
	const result = await ApiService.DeleteGym(id);

	if (result && !result.error) {
		dispatch(loadClubGyms(clubId, true));
	}

	return result;
};

export const addGymSchedule = (values, clubId) => async dispatch => {
	const result = await ApiService.AddSchedule({ ...values });

	if (result && !result.error) {
		dispatch(loadClubGyms(clubId, true));
	}

	return result;
};

export const updateGymSchedule = (values, clubId) => async dispatch => {
	const result = await ApiService.UpdateSchedule({ ...values });

	if (result && !result.error) {
		dispatch(loadClubGyms(clubId, true));
	}

	return result;
};

export const deleteGymSchedule = (id, clubId) => async dispatch => {
	const result = await ApiService.DeleteSchedule(id);

	if (result && !result.error) {
		dispatch(loadClubGyms(clubId, true));
	}

	return result;
};

export const addScheduleUsers = values => async dispatch => {
	const result = await ApiService.AddScheduleUsers({ ...values });

	if (result && !result.error) {
	}

	return result;
};

export default federationsPageSlice.reducer;

/*
function timeout(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
*/
