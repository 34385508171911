import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import Modal from "../../../Modal";
import FormikOnError from "../../../FormikOnError";
import GMap from "../../../GMap";
import GeocodeService from "../../../../core/services/geocode.service";
import debounce from "lodash.debounce";
import { addClubGym, updateClubGym } from "../../../../core/store/federationsSlice";

const geocode = debounce(async (address, setFieldValue) => {
	const coords = await GeocodeService.geocode(address);
	if (coords) {
		setFieldValue("lat", coords.lat);
		setFieldValue("lng", coords.lng);
	}
}, 500);

const validationSchema = {
	name: Yup.string().required("Required"),
	address: Yup.string().required("Required")
};

const AddGymForm = ({ closeModal, club, editedGym }) => {
	const dispatch = useDispatch();

	let pageTitle = "Add dojang";
	let submitTitle = "Add dojang";
	let initialZoom = 8;

	const initialValues = {
		name: "",
		address: "",
		lat: null,
		lng: null
	};

	if (editedGym) {
		pageTitle = "Edit gym";
		submitTitle = "Save";
		initialZoom = 15;

		for (let n in initialValues) {
			if (editedGym[n]) {
				initialValues[n] = editedGym[n];
			}
		}
		initialValues.lat = editedGym.latitude;
		initialValues.lng = editedGym.longitude;
	}

	const onSubmit = async (values, actions) => {
		const { name, address, lat, lng } = values;
		const fields = {
			name,
			address,
			club_id: club,
			latitude: lat,
			longitude: lng
		};

		let result;

		if (editedGym) {
			fields.id = editedGym.id;
			result = await dispatch(updateClubGym(fields, club));
		} else {
			result = await dispatch(addClubGym(fields));
		}

		if (result && result.error) {
			actions.setFieldError("general", result.error);
			actions.setSubmitting(false);
		} else {
			closeModal();
		}
	};

	return (
		<Modal closeModal={closeModal}>
			<Formik initialValues={initialValues} validationSchema={Yup.object().shape(validationSchema)} onSubmit={onSubmit}>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
					const positionChanged = async ({ lat, lng }) => {
						setFieldValue("lat", lat);
						setFieldValue("lng", lng);
						const address = await GeocodeService.reverseGeocode(lat, lng);
						setFieldValue("address", address);
					};

					const addressChanged = e => {
						handleChange(e);
						geocode(e.target.value, setFieldValue);
					};

					return (
						<form className="login-form" onSubmit={handleSubmit}>
							<FormikOnError>
								<fieldset>
									<h2>{pageTitle}</h2>
									<div className="form-block">
										<div className="col">
											<div className="form-row holder-error">
												<label>
													<span className="label">
														Name <sup>*</sup>
													</span>
													<input
														name="name"
														type="text"
														className={errors.name && touched.name ? "error" : ""}
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.name}
													/>
												</label>
											</div>
											<div className="form-row holder-error">
												<label>
													<span className="label">
														Address <sup>*</sup>
													</span>
													<input
														name="address"
														type="text"
														className={errors.address && touched.address ? "error" : ""}
														onChange={addressChanged}
														onBlur={handleBlur}
														value={values.address}
													/>
												</label>
											</div>
											<div className="form-row holder-error">
												<GMap lat={values.lat} lng={values.lng} positionSelectorHandler={positionChanged} zoom={initialZoom} />
											</div>
											{errors.general && <div className="form-error-message">{errors.general}</div>}
											<input type="submit" value={isSubmitting ? "Loading..." : submitTitle} disabled={isSubmitting} />
										</div>
									</div>
								</fieldset>
							</FormikOnError>
						</form>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default AddGymForm;
