import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getClubName, getClubOfficialName, getMemberName } from "../../../core/services/utils.service";
import { employeePositions } from "../../../core/store/dictionariesSlice";
import { FEDERATIONS_LEVELS, getFullpositions } from "../../../core/store/federationsSlice";
import { PRIVILEGES_LEVELS } from "../../../core/store/usersSlice";

import A from "../../A";
import Accordition from "../../Accordition";
import MebembersList from "../MebembersList";
import PositionsBlock from "../PositionsBlock";
import Gyms from "./Gyms/Gyms";

const Club = ({ club, deleteClub, showChildsButton, editFederation, addChildFederation, showUsersButton, showShortIcons, showFullPositions, showGyms }) => {
	const allowEdit = club && club.myPrivileges && club.myPrivileges.indexOf(PRIVILEGES_LEVELS.ClubEdit) > -1;
	const allowShowMembers = club && club.myPrivileges && club.myPrivileges.indexOf(PRIVILEGES_LEVELS.PersonalEdit) > -1;

	const countries = useSelector(state => state.pages.dictionaries.countries);
	const employeePositionsList = useSelector(state => employeePositions(state));
	const fullPositions = useSelector(state => getFullpositions(state));

	const edit = () => {
		editFederation(club);
	};

	const addFederation = () => {
		addChildFederation(club.id);
	};

	const [showMembersList, setShowMembersList] = useState(false);

	const toggleMembers = () => {
		setShowMembersList(value => !value);
	};

	if (!club || !club.id) {
		return <></>;
	}

	let social_media = [];
	if (club.social_media) {
		try {
			social_media = Object.entries(JSON.parse(club.social_media));
		} catch (e) {}
	}

	const positions = [];
	if (typeof club.positions !== "undefined") {
		employeePositionsList
			.filter(item => item.show_on_card)
			.forEach(item => {
				if (typeof club.positions[item.id] !== "undefined") {
					const phones = [];
					for (let i = 1; i <= 3; i++) {
						if (typeof club.positions[item.id][`phone${i}`] !== "undefined") {
							phones.push(club.positions[item.id][`phone${i}`]);
						} else {
							break;
						}
					}

					positions.push({
						id: item.id,
						title: item.name,
						peoples: [
							{
								user_id: club.positions[item.id].user_id,
								name: club.positions[item.id].fullname,
								phones
							}
						]
					});
				}
			});
	}

	const fullClubPositions = [];
	if (showFullPositions && typeof fullPositions[club.id] === "object") {
		employeePositionsList
			.filter(item => !item.show_on_card)
			.forEach(item => {
				if (typeof fullPositions[club.id][item.id] !== "undefined" && fullPositions[club.id][item.id].length) {
					const peoples = fullPositions[club.id][item.id].map(user => {
						return {
							user_id: user.user_id,
							name: getMemberName(user),
							phones: user.phones
						};
					});

					fullClubPositions.push({
						id: item.id,
						title: item.name,
						peoples
					});
				}
			});
	}

	const deleteClubClicked = id => {
		if (typeof deleteClub === "function") {
			deleteClub(id);
		}
	};

	return (
		<>
			<div className="info-block">
				<header className="federation-head">
					<Link to={`/federations/${club.id}`} className="title">
						{getClubName(club)}
					</Link>
					<div className="buttons-block">
						{allowEdit && typeof editFederation === "function" && (
							<button onClick={edit} className="edit-button" title="Edit federation">
								<img src="/images/edit.svg" />
							</button>
						)}
						{allowEdit && typeof addChildFederation === "function" && (
							<button onClick={addFederation} className="edit-button" title="Add child federation">
								<img src="/images/add.svg" />
							</button>
						)}

						{allowShowMembers && (
							<Link to={`/members/${club.id}`} className="link-button" title="Show full members list">
								<img src="/images/users.svg" width="20" height="20" />
							</Link>
						)}
						<Link to={`/federations/childs/${club.id}`} className="link-button" title="Show child federations">
							<img src="/images/sitemap.svg" width="20" height="20" />
						</Link>
						{allowEdit && (
							<button onClick={() => deleteClubClicked(club.id)} className="edit-button" title="Remove federation/club">
								<img src="/images/remove.svg" width="20" height="20" />
							</button>
						)}
					</div>
				</header>
				<div className="federation-block">
					<div className="federation-ava">
						<img className="ava-image" src={`https://data.taekwondo-itf.com/club_${club.id}_100.jpg?t=${club.photo_force}`} />
					</div>
					<div className="federation-info">
						<span className="federation-title">{getClubOfficialName(club)}</span>
						<ul className="info-block-list">
							<li>
								<span className="title">Country</span>
								<span className="info">{countries[club.country]}</span>
							</li>
							<li>
								<span className="title">City</span>
								<span className="info">{club.city}</span>
							</li>
							{club.address && club.address.length > 0 && (
								<li>
									<span className="title">Address</span>
									<span className="info">{club.address}</span>
								</li>
							)}
							{club.zip && (
								<li>
									<span className="title">Zip code</span>
									<span className="info">{club.zip}</span>
								</li>
							)}
						</ul>
						<ul className="info-block-list">
							{club.website && club.website.length > 0 && (
								<li>
									<span className="title">Web site</span>
									<span className="info">
										<a href={club.website} target="_blank">
											{club.website}
										</a>
									</span>
								</li>
							)}
							{social_media.length > 0 &&
								social_media.map(item => (
									<li key={item[0]}>
										<span className="title">{item[0]}</span>
										<span className="info">
											<a href={item[1]} target="_blank">
												{item[1]}
											</a>
										</span>
									</li>
								))}
						</ul>
						{positions.length > 0 && <PositionsBlock positions={positions} className={showFullPositions ? "without-bottom-border" : ""} />}

						{showFullPositions && fullClubPositions.length > 0 && (
							<Accordition title="All postions">
								<PositionsBlock positions={fullClubPositions} />
							</Accordition>
						)}
					</div>
				</div>
				<div className="show-more-btn-holder show-childs-block">
					{allowShowMembers && showUsersButton && club.club_level >= FEDERATIONS_LEVELS.National && (
						<A onClick={toggleMembers} className={showMembersList ? "members-opened" : ""}>
							show members
							<img src="/images/arr-accordion.svg" />
						</A>
					)}
				</div>
				{showMembersList && <MebembersList club={club.id} strictUsers={false} />}
			</div>
			{showGyms && <Gyms club={club.id} allowEdit={allowEdit} />}
		</>
	);
};

export default Club;
