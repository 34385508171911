// import "./wdyr";
import React from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import ReduxToastr from "react-redux-toastr";

import { history } from "./core/services/history.service";
import store from "./core/store/";
import "./core/services/i18n.service";

import Router from "./Router";

import "rc-collapse/assets/index.css";
import "rc-checkbox/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Router />
			<ReduxToastr />
		</ConnectedRouter>
	</Provider>,
	document.getElementById("wrapper")
);
