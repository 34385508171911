import { createSelector, createSlice } from "@reduxjs/toolkit";
import memoize from "lodash.memoize";

import ApiService from "../services/api.service";
import { makeHash, makeOptions } from "../services/utils.service";

export const DICTIONARIES_PAGE_REDUCER_KEY = "dictionaries";

const CERTIFICATE_TYPES = { 10: "Gup certificate", 11: "Dan certificate", 20: "Instructor certificate", 30: "Umpire Certificate", 40: "Recognition Plaque" };
export const CERTIFICATE_TYPES_ENUM = {};
for (let n in CERTIFICATE_TYPES) {
	CERTIFICATE_TYPES_ENUM[CERTIFICATE_TYPES[n]] = +n;
}

const dictionariesPageSlice = createSlice({
	name: DICTIONARIES_PAGE_REDUCER_KEY,
	initialState: {
		countries: {},
		employeePositions: [],
		degrees: {},
		privileges: {},
		instructorsLevels: {},
		umpiresLevels: {},
		socialMediaTypes: {},
		certificatesTypes: CERTIFICATE_TYPES, // FIXME получать с сервера? Там ошибка

		loading: false,
		loaded: false
	},
	reducers: {
		startLoading: state => {
			state.loading = true;
		},
		cancelInitiated: state => {
			state.loaded = false;
			state.loading = false;
		},
		finishLoading: state => {
			state.loading = false;
			state.loaded = true;
		},
		setCountries: (state, { payload }) => {
			state.countries = payload;
		},
		setEmployeePositions: (state, { payload }) => {
			state.employeePositions = payload;
		},
		setDegrees: (state, { payload }) => {
			state.degrees = payload;
		},
		setPrivileges: (state, { payload }) => {
			state.privileges = payload;
		},
		setInstructorsLevels: (state, { payload }) => {
			state.instructorsLevels = payload;
		},
		setUmpiresLevels: (state, { payload }) => {
			state.umpiresLevels = payload;
		},
		setSocialMediaTypes: (state, { payload }) => {
			state.socialMediaTypes = payload;
		}
	}
});

// export const { initApp } = dictionariesPageSlice.actions;

// selectors
const getDictionariesState = state => state.pages[DICTIONARIES_PAGE_REDUCER_KEY];
export const isLoading = state => getDictionariesState(state).loading;
export const isLoaded = state => getDictionariesState(state).loaded;
export const initiated = createSelector([isLoading, isLoaded], (loading, loaded) => {
	return loading || loaded;
});
export const countries = state => getDictionariesState(state).countries;
export const countriesList = createSelector([countries], countries => {
	let result = Object.entries(countries);
	result.sort((a, b) => a[1].localeCompare(b[1]));
	return result;
});
export const employeePositions = state => getDictionariesState(state).employeePositions;
export const employeePositionsForShow = createSelector([employeePositions], employeePositions => {
	return employeePositions.filter(item => item.show_on_card);
	//positionsForShow.sort((a, b) => a.id - b.id);
});
export const getDegrees = state => getDictionariesState(state).degrees;
export const getDegreesOrdered = createSelector([getDegrees], getDegrees => {
	const degrees = Object.values(getDegrees);
	degrees.sort((a, b) => a.id - b.id);
	return degrees;
});
export const getGupDegrees = createSelector([getDegreesOrdered], degrees => {
	return degrees.filter(item => item.id < 0);
});
export const getDanDegrees = createSelector([getDegreesOrdered], degrees => {
	return degrees.filter(item => item.id > 0);
});

export const getPrivilegesDictionary = state => getDictionariesState(state).privileges;

const getInstructorsLevelsList = state => getDictionariesState(state).instructorsLevels;
export const getInstructorsLevels = createSelector([getInstructorsLevelsList], instructorsLevels => {
	if (instructorsLevels && Array.isArray(instructorsLevels)) {
		return makeHash(instructorsLevels, "name");
	} else {
		return {};
	}
});

export const getInstructorsLevelsOptionsForCountry = createSelector([getInstructorsLevelsList], instructorsLevels =>
	memoize(country => {
		if (Array.isArray(instructorsLevels)) {
			const list = instructorsLevels.filter(item => item.country === country || typeof item.country === "undefined");
			list.sort((a, b) => a.orderby - b.orderby);
			return makeOptions(list, "name");
		} else {
			return [];
		}
	})
);

export const getInstructorsLevelsForCountry = createSelector([getInstructorsLevelsList], instructorsLevels =>
	memoize(country => {
		if (Array.isArray(instructorsLevels)) {
			const list = instructorsLevels.filter(item => item.country === country || typeof item.country === "undefined");
			list.sort((a, b) => a.orderby - b.orderby);
			return makeHash(list, "name");
		} else {
			return {};
		}
	})
);

const getUmpiresLevelsList = state => getDictionariesState(state).umpiresLevels;

export const getUmpiresLevels = createSelector([getUmpiresLevelsList], umpiresLevels => {
	if (umpiresLevels && Array.isArray(umpiresLevels)) {
		return makeHash(umpiresLevels, "name");
	} else {
		return {};
	}
});

export const getUmpiresLevelsOptionsForCountry = createSelector([getUmpiresLevelsList], umpiresLevels =>
	memoize(country => {
		if (Array.isArray(umpiresLevels)) {
			const list = umpiresLevels.filter(item => item.country === country || typeof item.country === "undefined");
			list.sort((a, b) => a.orderby - b.orderby);
			return makeOptions(list, "name");
		} else {
			return [];
		}
	})
);

export const getUmpiresLevelsForCountry = createSelector([getUmpiresLevelsList], umpiresLevels =>
	memoize(country => {
		if (Array.isArray(umpiresLevels)) {
			const list = umpiresLevels.filter(item => item.country === country || typeof item.country === "undefined");
			list.sort((a, b) => a.orderby - b.orderby);
			return makeHash(list, "name");
		} else {
			return {};
		}
	})
);

export const getSocialMediaTypes = state => getDictionariesState(state).socialMediaTypes;
export const getSocialMediaTypesForClubs = createSelector([getSocialMediaTypes], socialMediaTypes => {
	return socialMediaTypes.filter(item => item.clubs);
});
export const getSocialMediaTypesForUsers = createSelector([getSocialMediaTypes], socialMediaTypes => {
	return socialMediaTypes.filter(item => item.users);
});

export const getCertificatesTypes = state => getDictionariesState(state).certificatesTypes;

// actions
export const loadDictionaries = () => async (dispatch, getState) => {
	const state = getState();
	const isInitiated = initiated(state);

	if (isInitiated) {
		return;
	}

	dispatch(dictionariesPageSlice.actions.startLoading());

	try {
		const [
			{ countries },
			{ employeePositions },
			{ degrees },
			{ privileges },
			{ instructorsLevels },
			{ umpiresLevels },
			{ socialMediaTypes }
		] = await ApiService.GetDictionaries();

		if (degrees && countries && employeePositions && privileges) {
			dispatch(dictionariesPageSlice.actions.setCountries(countries));
			dispatch(dictionariesPageSlice.actions.setEmployeePositions(employeePositions));
			dispatch(dictionariesPageSlice.actions.setDegrees(makeHash(degrees)));
			dispatch(dictionariesPageSlice.actions.setPrivileges(makeHash(privileges)));
			dispatch(dictionariesPageSlice.actions.setInstructorsLevels(instructorsLevels));
			dispatch(dictionariesPageSlice.actions.setUmpiresLevels(umpiresLevels));
			dispatch(dictionariesPageSlice.actions.setSocialMediaTypes(Object.values(socialMediaTypes)));

			dispatch(dictionariesPageSlice.actions.finishLoading());
		} else {
			dispatch(dictionariesPageSlice.actions.cancelInitiated());
		}
	} catch (e) {
		dispatch(dictionariesPageSlice.actions.cancelInitiated());
	}
};

export default dictionariesPageSlice.reducer;
