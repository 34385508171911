import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteClubGym, getGyms, loadClubGyms } from "../../../../core/store/federationsSlice";
import GMap from "../../../GMap";
import Loading from "../../../Loading";
import AddGymForm from "./AddGymForm";
import Schedules from "./Schedules";

const Gyms = ({ club, allowEdit }) => {
	const dispatch = useDispatch();
	const gyms = useSelector(state => getGyms(state));

	useEffect(() => {
		if (club) {
			dispatch(loadClubGyms(club));
		}
	}, [club]);

	// add form
	const [showAddForm, setShowAddForm] = useState(false);
	const openAddFrom = () => {
		setShowAddForm(true);
	};

	const closeAddFrom = () => {
		setShowAddForm(false);
	};

	const gymsLoaded = typeof gyms[club] === "object" && Array.isArray(gyms[club]);

	const editDojang = useCallback(
		e => {
			const id = +e.currentTarget.dataset["dojangId"];

			if (gymsLoaded) {
				const editedGym = gyms[club].find(item => item.id === id);
				setShowAddForm(editedGym);
			}
		},
		[gyms, gymsLoaded]
	);

	const deleteDojang = useCallback(e => {
		const id = +e.currentTarget.dataset["dojangId"];
		if (window.confirm("Are you sure to delete this?")) {
			dispatch(deleteClubGym(id, club));
		}
	}, []);

	if (!gymsLoaded) {
		return <Loading />;
	}

	return (
		<>
			<div className="info-block dojangs">
				<header className="map-header">
					<h3>Dojangs</h3>
				</header>

				{gyms[club].map(item => (
					<div className="dojang-block" key={item.id}>
						<div className="logo-block">
							<span className="name">{item.name}</span>
							{allowEdit && (
								<div className="buttons-block">
									<button className="edit-button" title="Edit dojang" data-dojang-id={item.id} onClick={editDojang}>
										<img src="/images/edit.svg" />
									</button>
									<button className="edit-button" title="Remove dojang" data-dojang-id={item.id} onClick={deleteDojang}>
										<img src="/images/remove.svg" width="20" height="20" />
									</button>
								</div>
							)}
						</div>
						<GMap lat={item.latitude} lng={item.longitude} height={270} zoom={15} />
						<Schedules allowEdit={allowEdit} club={club} schedules={item.schedule} gymId={item.id} gymName={item.name} gymId={item.id}/>
					</div>
				))}

				{allowEdit && (
					<>
						<br />
						<br />
						<div className="show-more-btn-holder admin-btn-holder">
							<button className="show-more" onClick={openAddFrom}>
								<img src="/images/add.svg" /> Add dojang
							</button>
						</div>
					</>
				)}
			</div>
			{showAddForm && <AddGymForm club={club} closeModal={closeAddFrom} editedGym={typeof showAddForm === "object" ? showAddForm : null} />}
		</>
	);
};

export default Gyms;
