import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import Collapse from "rc-collapse";

import { getPrivileges, loadClubPrivileges } from "../../../core/store/federationsSlice";
import { loadUsersOptions } from "../../../core/store/usersSlice";

import Loading from "../../Loading";
import A from "../../A";
import Select from "../../Select";
import FederationFormPrivilegesBlock from "./FederationFormPrivilegesBlock";
import { getMemberName } from "../../../core/services/utils.service";

const FederationFormPrivileges = ({ clubId, updateHandler }) => {
	const dispatch = useDispatch();
	const privileges = useSelector(state => getPrivileges(state));

	const [addUser, setAddUser] = useState(null);

	const [users, setUsers] = useState(null);
	const [actualPrivileges, setActualPrivileges] = useState({});

	useEffect(() => {
		if (clubId) {
			dispatch(loadClubPrivileges(clubId));
		}
	}, [clubId]);

	useEffect(() => {
		if (clubId && typeof privileges[clubId] === "object") {
			console.log(privileges[clubId].users);

			let users = {};
			for (let user_id in privileges[clubId].users) {
				users[user_id] = {
					user_id: +user_id,
					fullname: getMemberName(privileges[clubId].users[user_id]),
					privileges: []
				};
			}

			privileges[clubId].privileges.forEach(item => {
				users[item.user_id].privileges.push(item.privilege);
			});

			const actualPrivileges = {};
			for (let user_id in users) {
				actualPrivileges[user_id] = users[user_id].privileges;
			}

			setUsers(users);
			setActualPrivileges(actualPrivileges);
		}
	}, [clubId, privileges]);

	const loadDebouncedOptions = useCallback(
		debounce((inputValue, callback) => {
			loadUsersOptions(inputValue).then(options => callback(options));
		}, 300),
		[]
	);

	if (!users) {
		return <Loading dark />;
	}

	const updateUserHandler = (userId, privileges) => {
		const newValue = {
			...actualPrivileges,
			[userId]: privileges
		};

		setActualPrivileges(newValue);
		updateHandler(newValue);
	};

	const addUserClicked = () => {
		if (addUser) {
			const newUser = {
				user_id: addUser.value,
				fullname: addUser.label,
				privileges: []
			};

			setUsers(value => ({ ...value, newUser }));
			setAddUser(null);
		}
	};

	// console.log(users);

	return (
		<>
			<Collapse accordion={true} className="privileges-collapse">
				{Object.values(users).map(item => (
					<Collapse.Panel key={item.user_id} header={item.fullname}>
						<FederationFormPrivilegesBlock user={item} updateHandler={updateUserHandler} />
					</Collapse.Panel>
				))}
			</Collapse>
			<div className="privilege-add">
				<div className="form-row holder-error">
					<label>
						<span className="label">Member</span>
						<Select placeholder={""} async loadOptions={loadDebouncedOptions} value={addUser} onChange={setAddUser}></Select>
					</label>
				</div>

				<div className="privilege-add-button">
					<A onClick={addUserClicked}>
						<img src="/images/add.svg" />
					</A>
				</div>
			</div>
		</>
	);
};

export default FederationFormPrivileges;
