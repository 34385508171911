import React, { useCallback } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import FormikOnError from "../../../FormikOnError";
import Select from "../../../Select";
import debounce from "lodash.debounce";
import { loadUsersOptions } from "../../../../core/store/usersSlice";
import { useDispatch } from "react-redux";
import { addGymSchedule, updateGymSchedule } from "../../../../core/store/federationsSlice";

const validationSchema = {
	coach: Yup.number().required("Required"),
	schedule: Yup.string().required("Required")
};

const AddScheduleForm = ({ gymId, club, closeForm, editSchedule }) => {
	const dispatch = useDispatch();

	let submitTitle = "Add schedule";

	const initialValues = {
		coach: null,
		coach_name: "",
		schedule: ""
	};

	if (editSchedule) {
		submitTitle = "Save";

		for (let n in initialValues) {
			if (editSchedule[n]) {
				initialValues[n] = editSchedule[n];
			}
		}
	}

	const loadDebouncedOptions = useCallback(
		debounce((inputValue, callback) => {
			loadUsersOptions(inputValue, true).then(options => callback(options));
		}, 300),
		[]
	);

	const onSubmit = async (values, actions) => {
		const fields = {
			...values,
			gym_id: gymId
		};

		delete fields.coach_name;

		let result;

		if (editSchedule) {
			fields.id = editSchedule.id;
			result = await dispatch(updateGymSchedule(fields, club));
		} else {
			result = await dispatch(addGymSchedule(fields, club));
		}

		if (result && result.error) {
			actions.setFieldError("general", result.error);
			actions.setSubmitting(false);
		} else {
			closeForm();
		}
	};

	return (
		<Formik initialValues={initialValues} validationSchema={Yup.object().shape(validationSchema)} onSubmit={onSubmit}>
			{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
				return (
					<form className="search-form" onSubmit={handleSubmit}>
						<FormikOnError>
							<div className="form-row holder-error">
								<Select
									name="coach"
									small
									placeholder="Coach"
									async
									loadOptions={loadDebouncedOptions}
									className={errors.coach && touched.coach ? "error" : ""}
									onChange={selectedOption => setFieldValue("coach", selectedOption ? selectedOption.value : null)}
									onBlur={handleBlur}
									defaultValue={{
										value: initialValues.coach,
										label: initialValues.coach_name
									}}
								></Select>
							</div>
							<div className="form-row holder-error">
								<input
									name="schedule"
									type="text"
									className={errors.schedule && touched.schedule ? "error" : ""}
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.schedule}
								/>
							</div>
							{errors.general && <div className="form-error-message">{errors.general}</div>}
							<input type="submit" value={isSubmitting ? "Loading..." : submitTitle} disabled={isSubmitting} />
							{editSchedule && (
								<div className="buttons-block">
									<button className="edit-button" title="Cancel edit" onClick={closeForm}>
										<img src="/images/close.svg" width="16" height="16" />
									</button>
								</div>
							)}
						</FormikOnError>
					</form>
				);
			}}
		</Formik>
	);
};

export default AddScheduleForm;
