import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import fecha from "fecha";
import debounce from "lodash.debounce";
import DatePicker from "react-datepicker";

import {
	getCertificatesTypes,
	getInstructorsLevelsOptionsForCountry,
	getUmpiresLevelsOptionsForCountry,
	CERTIFICATE_TYPES_ENUM,
	getDanDegrees,
	getGupDegrees
} from "../../../core/store/dictionariesSlice";
import { addUserCertificate, loadUsersOptions } from "../../../core/store/usersSlice";

import Modal from "../../Modal";
import Select from "../../Select";
import FormikOnError from "../../FormikOnError";

const rankTitles = {
	[CERTIFICATE_TYPES_ENUM["Gup certificate"]]: "Gup",
	[CERTIFICATE_TYPES_ENUM["Dan certificate"]]: "Dan",
	[CERTIFICATE_TYPES_ENUM["Instructor certificate"]]: "Instructor level",
	[CERTIFICATE_TYPES_ENUM["Umpire Certificate"]]: "Umpire level"
};

const validationSchema = {
	user_certificate_type_id: Yup.number().required("Required"),
	instructor_id: Yup.number().required("Required"),
	certificate_number: Yup.string().required("Required"),
	rank: Yup.number().when(["user_certificate_type_id"], {
		is: user_certificate_type_id => user_certificate_type_id !== CERTIFICATE_TYPES_ENUM["Recognition Plaque"],
		then: Yup.number().required("Required")
	}),

	// rank: Yup.number().when(["user_certificate_type_id"], (user_certificate_type_id, schema) => {
	// 	console.log(schema);
	// 	return schema;
	// }),
	// rank: Yup.number().required("Required"),
	date_created: Yup.date().required("Required"),
	date_issued: Yup.date().required("Required")
};

const AddCertificateForm = ({ user, country, closeModal, setCertificateType }) => {
	const dispatch = useDispatch();
	const certificatesTypes = useSelector(state => getCertificatesTypes(state));
	const gupDegress = useSelector(state => getGupDegrees(state));
	const danDegress = useSelector(state => getDanDegrees(state));
	const instructorsLevelsOptions = useSelector(state => getInstructorsLevelsOptionsForCountry(state));
	const umpiresLevelsOptions = useSelector(state => getUmpiresLevelsOptionsForCountry(state));

	const certificatesTypesOptions = Object.entries(certificatesTypes).map(item => {
		return { value: +item[0], label: item[1] };
	});

	const initialValues = {
		user_certificate_type_id: null,
		certificate_number: "",
		instructor_id: null,
		date_created: null,
		date_issued: null,
		rank: ""
	};

	const loadDebouncedOptions = useCallback(
		debounce((inputValue, callback) => {
			loadUsersOptions(inputValue, true).then(options => callback(options));
		}, 300),
		[]
	);

	const onSubmit = async (values, actions) => {
		const fields = {
			...values,
			user_id: user,
			date_created: fecha.format(values.date_created, "DD-MM-YYYY"),
			date_issued: fecha.format(values.date_issued, "DD-MM-YYYY")
		};

		const result = await dispatch(addUserCertificate(fields));

		if (result && result.error) {
			actions.setFieldError("general", result.error);
			actions.setSubmitting(false);
		} else {
			setCertificateType(fields.user_certificate_type_id);
			closeModal();
		}
	};

	const RankField = ({ values, errors, touched, setFieldValue }) => {
		const user_certificate_type_id = values.user_certificate_type_id;
		if (!user_certificate_type_id || user_certificate_type_id === CERTIFICATE_TYPES_ENUM["Recognition Plaque"]) {
			return null;
		} else {
			let options = [];
			if (user_certificate_type_id === CERTIFICATE_TYPES_ENUM["Gup certificate"]) {
				options = gupDegress.map(item => ({ label: item.description, value: item.id }));
			} else if (user_certificate_type_id === CERTIFICATE_TYPES_ENUM["Dan certificate"]) {
				options = danDegress.map(item => ({ label: item.description, value: item.id }));
			} else if (user_certificate_type_id === CERTIFICATE_TYPES_ENUM["Instructor certificate"]) {
				options = instructorsLevelsOptions(country);
			} else if (user_certificate_type_id === CERTIFICATE_TYPES_ENUM["Umpire Certificate"]) {
				options = umpiresLevelsOptions(country);
			}

			return (
				<div className="form-row holder-error">
					<label>
						<span className="label">
							{rankTitles[user_certificate_type_id]} <sup>*</sup>
						</span>
						<Select
							name="rank"
							placeholder={""}
							options={options}
							className={errors.rank && touched.rank ? "error" : ""}
							onChange={selectedOption => setFieldValue("rank", selectedOption.value)}
							value={options ? options.find(option => option.value === values.rank) ?? "" : ""}
						></Select>
					</label>
				</div>
			);
		}
	};

	return (
		<Modal closeModal={closeModal}>
			<Formik initialValues={initialValues} validationSchema={Yup.object().shape(validationSchema)} onSubmit={onSubmit}>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
					return (
						<form className="login-form" onSubmit={handleSubmit}>
							<FormikOnError>
								<fieldset>
									<h2>Add certificate</h2>
									<div className="form-block">
										<div className="two-columns">
											<div className="col">
												<div className="form-row holder-error">
													<label>
														<span className="label">
															Certificate type <sup>*</sup>
														</span>
														<Select
															name="user_certificate_type_id"
															placeholder={""}
															isSearchable={false}
															options={certificatesTypesOptions}
															className={errors.user_certificate_type_id && touched.user_certificate_type_id ? "error" : ""}
															onChange={selectedOption => {
																setFieldValue("rank", "");
																setFieldValue("user_certificate_type_id", selectedOption.value);
															}}
															value={
																certificatesTypesOptions
																	? certificatesTypesOptions.find(option => option.value === values.user_certificate_type_id)
																	: ""
															}
														></Select>
													</label>
												</div>
												<RankField values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} />
												<div className="form-row holder-error">
													<label>
														<span className="label">
															Certificate number <sup>*</sup>
														</span>
														<input
															name="certificate_number"
															type="text"
															className={errors.certificate_number && touched.certificate_number ? "error" : ""}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.certificate_number}
														/>
													</label>
												</div>
												<div className="form-row holder-error">
													<label>
														<span className="label">
															Instructor <sup>*</sup>
														</span>

														<Select
															name="instructor_id"
															placeholder={""}
															async
															loadOptions={loadDebouncedOptions}
															className={errors.instructor_id && touched.instructor_id ? "error" : ""}
															onChange={selectedOption =>
																setFieldValue("instructor_id", selectedOption ? selectedOption.value : null)
															}
															onBlur={handleBlur}
														></Select>
													</label>
												</div>
												<div className="form-row holder-error date-row">
													<label>
														<span className="label">
															Created date <sup>*</sup>
														</span>
														<DatePicker
															name="date_created"
															selected={values.date_created}
															dateFormat="dd.MM.yyyy"
															onChange={date => setFieldValue("date_created", date)}
															className={errors.date_created && touched.date_created ? "error" : ""}
															showYearDropdown
															onBlur={handleBlur}
															shouldCloseOnSelect={true}
														/>
													</label>
												</div>
												<div className="form-row holder-error date-row">
													<label>
														<span className="label">
															Issued date <sup>*</sup>
														</span>
														<DatePicker
															name="date_issued"
															selected={values.date_issued}
															dateFormat="dd.MM.yyyy"
															onChange={date => setFieldValue("date_issued", date)}
															className={errors.date_issued && touched.date_issued ? "error" : ""}
															showYearDropdown
															onBlur={handleBlur}
															shouldCloseOnSelect={true}
														/>
													</label>
												</div>
												{errors.general && <div className="form-error-message">{errors.general}</div>}
												<input type="submit" value={isSubmitting ? "Loading..." : "Add certificate"} disabled={isSubmitting} />
											</div>
										</div>
									</div>
								</fieldset>
							</FormikOnError>
						</form>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default AddCertificateForm;
