import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { formatAge, getMemberName } from "../../core/services/utils.service";
import { getList, loadClubUsers } from "../../core/store/usersSlice";

import Loading from "../Loading";
import Dan from "./Dan";

const MebembersList = ({ club, strictUsers }) => {
	const dispatch = useDispatch();

	const usersByClub = useSelector(state => getList(state));

	useEffect(() => {
		dispatch(loadClubUsers(club, strictUsers));
	}, []);

	const users = usersByClub[club];

	if (!users || typeof users.loading !== "undefined") {
		return <Loading dark />;
	}

	return (
		<div className="info-block table">
			<div className="search-form-club">
				<header className="head">
					<span className="title">List of Members</span>
				</header>
			</div>
			<table className="members-table">
				<thead>
					<tr>
						<th>
							<div>Name</div>
						</th>
						<th>
							<div>Age</div>
						</th>
						<th>
							<div>Gender</div>
						</th>
						<th>
							<div>Rank</div>
						</th>
					</tr>
				</thead>
				{users.users.length > 0 && (
					<tbody>
						{users.users.map(user => {
							return (
								<tr key={user.id}>
									<td>
										<div>
											<Link to={`/member/${user.id}`}>{getMemberName(user)}</Link>
										</div>
									</td>
									<td>
										<div>{formatAge(user.age)}</div>
									</td>
									<td>
										<div>{user.sex === "M" ? "Male" : "Female"}</div>
									</td>
									<td>
										<div>
											<Dan degree={user.degree} />
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				)}
			</table>
			<div className="show-more-btn-holder show-childs-block">
				<Link to={`/members/${club}`}>
					show all members
					<img src="/images/arr-left.svg" height="12" />
				</Link>
			</div>
		</div>
	);
};

export default MebembersList;
