import React, { useState } from "react";

const Accordition = ({ title, children }) => {
	const [isOpened, setIsOpened] = useState(false);

	const toggleOpened = () => {
		setIsOpened(val => !val);
	};

	return (
		<div className="accordition">
			<div onClick={toggleOpened} className={"accordition-title " + (isOpened ? "opened" : "")}>
				{title}
			</div>
			{isOpened && <div className="accordition-content">{children}</div>}
		</div>
	);
};

export default Accordition;
