export const array_diff = (arr1, arr2) => arr1.filter(item => arr2.indexOf(item) === -1);

export const isUoN = value => {
	return value == null;
};

export const formatAge = age => {
	if (Number.isInteger(age) && age > 0) {
		return age;
	}

	return "-";
};

export const getMemberName = user => {
	if (!user) {
		return "";
	}

	const result = [];

	if (user.given_name && user.given_name.length) {
		result.push(user.given_name);
	}

	if (user.family_name && user.family_name.length) {
		result.push(user.family_name);
	}

	if (!result.length) {
		if (user.n_given_name && user.n_given_name.length) {
			result.push(user.n_given_name);
		}

		if (user.n_family_name && user.n_family_name.length) {
			result.push(user.n_family_name);
		}
	}

	return result.join(" ");
};

export const getClubName = club => {
	if (!club) {
		return "";
	}

	if (club.name && club.name.length) {
		return club.name;
	}
	if (club.n_name && club.n_name.length) {
		return club.n_name;
	}
	return "";
};

export const getClubOfficialName = club => {
	if (!club) {
		return "";
	}

	let name = "";

	if (club.official_name && club.official_name.length) {
		name = club.official_name;
	}

	if (club.n_official_name && club.n_official_name.length) {
		if (name.length) {
			name += " (" + club.n_official_name + ")";
		} else {
			name = club.n_official_name;
		}
	}
	return name;
};

export const makeHash = (collection, field) => {
	const hash = {};
	collection.forEach(item => {
		if (field) {
			hash[item.id] = item[field];
		} else {
			hash[item.id] = item;
		}
	});
	return hash;
};

export const makeOptions = (collection, field) => {
	return collection.map(item => ({ label: item[field], value: item.id }));
};
