import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ breadcrumbs }) => {
	return (
		<ul className="breadcrumps">
			<li>
				<Link to="/home">Home</Link>
			</li>
			{breadcrumbs &&
				breadcrumbs.map((item, i) => {
					if (item[1]) {
						return (
							<li key={i}>
								<Link to={item[1]}>{item[0]}</Link>
							</li>
						);
					} else {
						return <li key={i}>{item[0]}</li>;
					}
				})}
		</ul>
	);
};

export default Breadcrumbs;
