import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Checkbox from "rc-checkbox";

import { getPrivilegesDictionary } from "../../../core/store/dictionariesSlice";

const FederationFormPrivilegesBlock = ({ user, updateHandler }) => {
	const privilegesDict = useSelector(state => getPrivilegesDictionary(state));

	const [privileges, setPrivileges] = useState();

	useEffect(() => {
		setPrivileges(new Set(user.privileges));
	}, [user]);

	const privilegesChanged = (checked, privilege_id) => {
		const newPrivileges = new Set(privileges);
		if (checked) {
			newPrivileges.add(privilege_id);
		} else {
			newPrivileges.delete(privilege_id);
		}

		setPrivileges(newPrivileges);
		updateHandler(user.user_id, Array.from(newPrivileges));
	};

	return (
		<div>
			{Object.values(privilegesDict).map(item => (
				<div key={item.id}>
					<label>
						<Checkbox defaultChecked={user.privileges.indexOf(item.id) > -1} onChange={e => privilegesChanged(e.target.checked, item.id)} /> &nbsp;
						{item.name}
					</label>
				</div>
			))}
		</div>
	);
};

export default FederationFormPrivilegesBlock;
