import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getClubName } from "../../core/services/utils.service";
import { federations, getClubBreadcrumbs, loadClub, loadFullClubPositions } from "../../core/store/federationsSlice";

import Club from "../../components/Blocks/Club/Club";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import { PRIVILEGES_LEVELS } from "../../core/store/usersSlice";
import AddFederationFrom from "../../components/Blocks/AddFederationForm/AddFederationForm";

const FederationsDetails = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const clubs = useSelector(state => federations(state));

	useEffect(() => {
		if (id) {
			dispatch(loadClub(id));
			dispatch(loadFullClubPositions(id));
		}
	}, [id]);

	const [breadcrumbs, setBreadcrumbs] = useState([]);

	const loadBreadcrumbs = async id => {
		const b = await dispatch(getClubBreadcrumbs(id));
		setBreadcrumbs(b);
	};

	useEffect(() => {
		loadBreadcrumbs(id);
	}, [clubs, id]);

	// edit form
	const [showAddForm, setShowAddForm] = useState(false);

	const closeAddFrom = () => {
		setShowAddForm(false);
	};

	const editFederation = club => {
		setShowAddForm(true);
	};

	const club = clubs[id];

	const loaded = typeof club === "object" && club.id === +id;

	const allowEdit = club && club.myPrivileges && club.myPrivileges.indexOf(PRIVILEGES_LEVELS.ClubEdit) > -1;

	return (
		<>
			<Layout breadcrumbs={breadcrumbs} title={getClubName(club)}>
				{!loaded ? (
					<Loading />
				) : (
					<div className="info-wrapper-blocks">
						<div className="two-columns">
							<div className="col">
								<Club club={club} editFederation={allowEdit ? editFederation : () => {}} showShortIcons showFullPositions showGyms />
							</div>
						</div>
					</div>
				)}
			</Layout>
			{showAddForm && <AddFederationFrom closeModal={closeAddFrom} editedClub={club} />}
		</>
	);
};

export default FederationsDetails;
