import React from "react";

import A from "../A";

const Footer = () => {
	return (
		<footer id="footer">
			<div className="center">
				<div className="footer-top">
					<div className="left-block">
						<strong className="logo-foot">
							<div className="visual small-logo">
								<img src="/images/logo2_1.png" />
							</div>
							<span className="user-name">World Database of ITF Member</span>
						</strong>
					</div>
					<div className="members-block">
						<ul className="members-list">
							<li>
								<img src="/images/icon1.png" />
							</li>
							<li>
								<img src="/images/icon2.png" />
							</li>
							<li>
								<img src="/images/icon4.png" />
							</li>
							<li>
								<img src="/images/icon3.png" />
							</li>
							<li>
								<img src="/images/icon5.png" />
							</li>
						</ul>
					</div>
				</div>
				<div className="footer-bottom">
					<div className="copyright-block">
						<span className="copy">Copyright © All rights reserved. | ITF</span>
						<A className="privacy-policy">Сonfidentiality policy</A>
						<ul className="companies-list">
							<li>
								<A>
									<img src="/images/company1.svg" />
								</A>
							</li>
							<li>
								<A>
									<img src="/images/company2.svg" />
								</A>
							</li>
						</ul>
					</div>
					<div className="members-block">
						<p>
							iSCORE is a complex FAIRPLAY IT concept that comprises and embodies a number of fundamental values integral to martial arts. iSCORE
							FAIRPLAY software and hardware solutions are dedicated to taking the objective and unbiased scoring to the next level.
						</p>
					</div>
					<div className="companies-right-block">
						<ul className="companies-list">
							<li>
								<A>
									<img src="/images/company3.svg" />
								</A>
							</li>
							<li>
								<A>
									<img src="/images/company4.svg" />
								</A>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
