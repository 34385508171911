import { createSlice } from "@reduxjs/toolkit";
import { toastr } from "react-redux-toastr";

import ApiService from "../services/api.service";
import { getMemberName } from "../services/utils.service";

export const PRIVILEGES_LEVELS = Object.freeze(JSON.parse(process.env.REACT_APP_PRIVILEGES_LEVEL));

export const USERS_PAGE_REDUCER_KEY = "users";

const usersPageSlice = createSlice({
	name: USERS_PAGE_REDUCER_KEY,
	initialState: {
		list: {},
		users: {},
		certificates: {}
	},
	reducers: {
		startClubLoading: (state, { payload: { clubId, page } }) => {
			if (typeof state.list[clubId] === "undefined") {
				state.list[clubId] = {};
			}
			state.list[clubId].loading = page;
		},
		setClubUsers: (state, { payload: { clubId, page, users, usersCount } }) => {
			state.list[clubId] = {
				users,
				page,
				usersCount
			};
		},
		removeClub: (state, { payload }) => {
			state.list[payload] = {};
		},
		//
		startUserLoading: (state, { payload: id }) => {
			state.users[id] = "loading";
		},
		setUser: (state, { payload: { id, user } }) => {
			state.users[id] = user;
		},
		unloadUser: (state, { payload: id }) => {
			delete state.users[id];
		},
		appendUserToList: (state, { payload: { clubId, user } }) => {
			state.list[clubId].users.push(user);
			state.list[clubId].usersCount++;
		},
		updateUser: (state, { payload }) => {
			state.users[payload.id] = payload;
		},
		userSetPhotoForce: (state, { payload: id }) => {
			if (typeof state.users[id] === "object") {
				state.users[id].photo_force = +new Date();
			}
		},
		removeUser: (state, { payload: { id, clubId } }) => {
			delete state.users[id];
			if (typeof state.list[clubId] !== "undefined" && Array.isArray(state.list[clubId].users)) {
				state.list[clubId].users = state.list[clubId].users.filter(item => {
					console.log(item.id);
					return item.id !== id;
				});
			}
		},
		startLoadCertificates: (state, { payload: id }) => {
			state.certificates[id] = "loading";
		},
		setCertificates: (state, { payload: { id, certificates } }) => {
			state.certificates[id] = certificates;
		},
		clearCertificates: (state, { payload: id }) => {
			delete state.certificates[id];
		}
	}
});

export const { unloadUser } = usersPageSlice.actions;

const getUsersState = state => state.pages[USERS_PAGE_REDUCER_KEY];
export const getList = state => getUsersState(state).list;
export const getUsers = state => getUsersState(state).users;
export const getCertificates = state => getUsersState(state).certificates;

export const loadClubUsers = (clubId, clubStrict, page = 1) => async (dispatch, getState) => {
	let state = getState();
	let list = getList(state);

	if (typeof list[clubId] !== "undefined" && (list[clubId].page === page || list[clubId].loading === page)) {
		return;
	}

	dispatch(usersPageSlice.actions.startClubLoading({ clubId, page }));

	const { users, usersCount } = await ApiService.getUsers(clubId, clubStrict, page);

	state = getState();
	list = getList(state);

	if (list[clubId].loading === page) {
		dispatch(usersPageSlice.actions.setClubUsers({ clubId, users, usersCount, page }));
	}
};

export const unloadClubUsers = clubId => async dispatch => {
	dispatch(usersPageSlice.actions.removeClub(clubId));
};

export const loadUser = id => async (dispatch, getState) => {
	let state = getState();
	let users = getUsers(state);

	if (typeof users[id] !== "undefined") {
		return;
	}

	dispatch(usersPageSlice.actions.startUserLoading(id));

	const user = await ApiService.getUser(id);

	dispatch(usersPageSlice.actions.setUser({ id, user }));
};

export const addUser = values => async dispatch => {
	const result = await ApiService.addUser({ ...values });

	const { id } = result;

	if (id) {
		const user = await ApiService.getUser(id);
		dispatch(usersPageSlice.actions.appendUserToList({ clubId: values.club_id, user }));
	}

	return result;
};

export const updateUser = (values, allFields) => async dispatch => {
	const result = await ApiService.updateUser(values);

	if (result && !result.error) {
		const user = await ApiService.getUser(values.id);
		dispatch(usersPageSlice.actions.updateUser(user));
	}

	return result;
};

export const uploadPhoto = (imgData, id, x, y, size) => async dispatch => {
	await ApiService.uploadPicture(imgData, "users", id, x, y, size);
	dispatch(usersPageSlice.actions.userSetPhotoForce(id));
};

export const grantPosition = (userId, clubId, position) => async dispatch => {
	await ApiService.grantPosition(userId, clubId, position);
};

export const revokePosition = (userId, clubId, position) => async dispatch => {
	await ApiService.revokePsition(userId, clubId, position);
};

export const processPositions = privileges => async dispatch => {
	await Promise.all(
		privileges.map(item => {
			if (item[0] === "grant") {
				return dispatch(grantPosition(item[1], item[2], item[3]));
			} else {
				return dispatch(revokePosition(item[1], item[2], item[3]));
			}
		})
	);
};

export const grantPrivileges = (userId, clubId, privileges) => async dispatch => {
	await ApiService.grantPrivileges(userId, clubId, privileges);
};

export const revokePrivileges = (userId, clubId, privileges) => async dispatch => {
	await ApiService.revokePrivileges(userId, clubId, privileges);
};

export const processPrivileges = privileges => async dispatch => {
	await Promise.all(
		privileges.map(item => {
			if (item[0] === "grant") {
				return dispatch(grantPrivileges(item[1], item[2], item[3]));
			} else {
				return dispatch(revokePrivileges(item[1], item[2], item[3]));
			}
		})
	);
};

export const deleteUser = (id, clubId) => async dispatch => {
	const result = await ApiService.DeleteUser(id);

	if (result && !result.error) {
		dispatch(usersPageSlice.actions.removeUser({ id, clubId }));
	} else if (result && result.error) {
		toastr.error(result.error);
	}

	return result;
};

export const loadUserCertificates = (id, force) => async (dispatch, getState) => {
	const state = getCertificates(getState());

	if (typeof state[id] !== "undefined" && !force) {
		return;
	}

	dispatch(usersPageSlice.actions.startLoadCertificates(id));

	const { usersCertificates: certificates } = await ApiService.GetUserCertificates(id);

	dispatch(usersPageSlice.actions.setCertificates({ id, certificates }));
};

export const addUserCertificate = values => async dispatch => {
	const result = await ApiService.AddUsersCertificate({ ...values });

	if (result && !result.error) {
		dispatch(loadUserCertificates(values.user_id, true));
	}

	return result;
};

export const deleteUserCertificate = (id, userId) => async dispatch => {
	const result = await ApiService.DeleteUsersCertificate(id);

	if (result && !result.error) {
		dispatch(loadUserCertificates(userId, true));
	} else if (result && result.error) {
		toastr.error(result.error);
	}

	return result;
};

// not actions
export const loadUsersOptions = (input, onlyInstructors = false) => {
	return new Promise(async resolve => {
		const result = await ApiService.suggestUser(input, onlyInstructors);
		const options = result.users.map(item => ({
			value: item.id,
			label: getMemberName(item)
		}));
		resolve(options);
	});
};

export default usersPageSlice.reducer;
