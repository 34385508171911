import React from "react";
import Modal from "../../../Modal";
import AddScheduleMemberForm from "./AddScheduleMemberForm";

const ScheduleMembers = ({ schedule, gymName, closeModal, allowEdit }) => {
	return (
		<Modal closeModal={closeModal}>
			<h2>
				{gymName}, {schedule.coach_name}, {schedule.schedule}
			</h2>
			<div className="info-wrapper-blocks">
				<div className="col-club">
					<div className="info-block table">
						<table className="members-table">
							<thead>
								<tr>
									<th>
										<div>Name</div>
									</th>
									{allowEdit && <th></th>}
								</tr>
							</thead>
							<tbody></tbody>
						</table>
					</div>
				</div>
				{allowEdit && <AddScheduleMemberForm scheduleId={schedule.id} />}
			</div>
		</Modal>
	);
};

export default ScheduleMembers;
