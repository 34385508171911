import React, { useEffect } from "react";
import ApiService from "../../core/services/api.service";

const TestPage = () => {
	useEffect(() => {
		ApiService.GetGyms(1);
	}, []);

	return <div style={{ margin: 100 }}></div>;
};

export default TestPage;
