import axios from "axios";

class GeocodeService {
	static async geocode(address) {
		const key = process.env.REACT_APP_GMAPS_API_KEY;
		const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${key}`;

		let result = null;

		try {
			const response = await axios(url);

			if (
				response &&
				response.status === 200 &&
				response.data &&
				response.data.status === "OK" &&
				response.data.results &&
				response.data.results.length
			) {
				result = response.data.results[0].geometry.location;
			}
		} catch (e) {}

		return result;
	}

	static async reverseGeocode(lat, lng) {
		const key = process.env.REACT_APP_GMAPS_API_KEY;
		const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${key}&language=en`; // 

		let result = null;

		try {
			const response = await axios(url);

			if (
				response &&
				response.status === 200 &&
				response.data &&
				response.data.status === "OK" &&
				response.data.results &&
				response.data.results.length
			) {
				result = response.data.results[0].formatted_address;
			}
		} catch (e) {}

		return result;
	}
}

export default GeocodeService;
