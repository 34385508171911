import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { logout } from "../../core/store/appSlice";

import A from "../A";

const Header = ({ userName }) => {
	const dispatch = useDispatch();
	const location = useLocation();

	const logoutClicked = () => {
		dispatch(logout());
	};

	return (
		<header id="header">
			<div className="center">
				<strong className="logo">
					<Link to="/home">
						<div className="visual">
							<img src="/images/logo2_1.png" />
						</div>
						<span> World Database of ITF Member </span>
					</Link>
				</strong>
				<div className="user-block">
					<div className="holder-lang">
						<div className="lang-holder">
							<ul className="lang">
								<li className="active">
									<A>
										<i className="ico">
											<img src="/images/br.svg" width="28" height="17" />
										</i>
										<span>ENG</span>
									</A>
								</li>
							</ul>
						</div>
					</div>
					<button className="btn-logged">
						<i className="icon-user">
							<img src="/images/icon_user.svg" />
						</i>
						<span>{userName}</span>
						<i className="icon-arr">
							<img src="/images/arr-down.svg" />
						</i>
						<div className="drop">
							<ul>
								<li>
									<A onClick={logoutClicked}>Exit</A>
								</li>
							</ul>
						</div>
					</button>
				</div>
				<nav id="nav">
					<ul>
						<li>
							<A>Rating of Members</A>
						</li>
						<li className={location.pathname.startsWith("/federations") ? "active" : ""}>
							<Link to="/federations">List of Federations</Link>
						</li>
						{/* <li className={location.pathname === "/countries" ? "active" : ""}>
							<Link to="/countries">List of Countries</Link>
						</li> */}
					</ul>
				</nav>
			</div>
		</header>
	);
};

export default Header;
