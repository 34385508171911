import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getUsers, loadUser, unloadUser } from "../../core/store/usersSlice";
import { federations, getClubBreadcrumbs, loadClub } from "../../core/store/federationsSlice";

import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import Member from "../../components/Blocks/Member/Member";
import { getSession } from "../../core/store/appSlice";

const MemberPage = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const userSession = useSelector(state => getSession(state));

	useEffect(() => {
		if (id) {
			dispatch(loadUser(id));
		}
		return () => {
			if (userSession.user_id !== +id) {
				dispatch(unloadUser(id));
			}
		};
	}, [id]);

	const users = useSelector(state => getUsers(state));
	const clubs = useSelector(state => federations(state));

	const user = users[id];

	useEffect(() => {
		if (user && user.club_id) {
			dispatch(loadClub(user.club_id));
		}
	}, [user]);

	const [breadcrumbs, setBreadcrumbs] = useState([]);

	const loadBreadcrumbs = async id => {
		const b = await dispatch(getClubBreadcrumbs(id));
		setBreadcrumbs(b);
	};

	useEffect(() => {
		if (user && user.club_id) {
			loadBreadcrumbs(user.club_id);
		}
	}, [clubs, user]);

	let component = <Loading />;
	if (typeof user === "object") {
		if (user === null) {
			component = <div>Not found</div>;
		} else {
			component = <Member user={user} club={clubs[user.club_id]} />;
		}
	}

	return <Layout breadcrumbs={breadcrumbs}>{component}</Layout>;
};

export default MemberPage;
