import React from "react";
import { Link } from "react-router-dom";

const PositionsBlock = ({ positions, className }) => {
	return (
		<ul className={`info-block-list club-positions-list ${className}`}>
			{positions.map(item => (
				<React.Fragment key={item.id}>
					<li>
						<img
							className="club-positions-list-photo ava-image"
							src={`https://data.taekwondo-itf.com/user_${item.peoples[0].user_id}_100.jpg`}
							width="100"
						/>
						<span className="title">{item.title}</span>
						<span className="info">
							<Link to={`/member/${item.peoples[0].user_id}`}>{item.peoples[0].name}</Link>
						</span>
					</li>
					<li>
						{item.peoples[0].phones && (
							<>
								<span className="title">Mobile phone</span>
								<span className="info info-list">
									{item.peoples[0].phones.map((phone, i) => (
										<div key={i}>{phone}</div>
									))}
								</span>
							</>
						)}
					</li>
					<li></li>
					{item.peoples.length > 1 &&
						item.peoples
							.filter((el, i) => i > 0)
							.map((el, j) => (
								<React.Fragment key={item.id + "" + j}>
									<li>
										<img
											className="club-positions-list-photo ava-image"
											src={`https://data.taekwondo-itf.com/user_${el.user_id}_100.jpg`}
											width="100"
										/>
										<span className="info">
											<Link to={`/member/${el.user_id}`}>{el.name}</Link>
										</span>
									</li>
									<li>
										{el.phones && (
											<>
												<span className="title">Mobile phone</span>
												<span className="info info-list">
													{el.phones.map((phone, i) => (
														<div key={i}>{phone}</div>
													))}
												</span>
											</>
										)}
									</li>
									<li></li>
								</React.Fragment>
							))}
				</React.Fragment>
			))}
		</ul>
	);
};

export default PositionsBlock;
